export const locale = {
	lang: 'tr',
	data: {
		TRANSLATOR: {
			SELECT: 'Dil Seçiniz',
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			APPS: 'Apps',
			DASHBOARD: 'Pano',
			MAP: 'Harita',
			RESERVATION: 'Rezervasyon/Sulama',
			DIRECTORATES: {
				MAIN: 'Kurumlar',
				MANAGE: 'Kurum Yönetimi',
				USERS: 'Kurum Kullanıcıları',
			},
			COOPS: {
				MAIN: 'Kooperatifler',
				MANAGE: 'Kooperatif Yönetimi',
				USERS: 'Kooperatif Kullanıcıları',
				WELLS: 'Kuyular',
				HYDRANTS: 'Hidrantlar',
				FIELDS: 'Tarlalar',
				SETTINGS: 'Ayarlar',
				PRICETARIFFS: 'Fiyat Tarifeleri',
				LINES: 'Sulama Hatları',
				MAINS: 'Sulama Şebekeleri'

			},
			MEMBERS: {
				MAIN: 'Üyeler',
				MANAGE: 'Üye Yönetimi',
				USERS: 'Üye Kullanıcıları'
			},
			REPORTS: {
				MAIN: 'Raporlar',
				WELL_PRODUCTION: 'Üretim Raporu',
				MEMBER_PRODUCTION: 'Üye Üretim Raporu',
				FIELD_PRODUCTION: 'Tarla Üretim Raporu',
				MEMBER_CONSUMPTION: 'Üye Tüketim Raporu',
				MEMBER_CASH: 'Üye Hesap Döküm Raporu',
				FIELD_WELL_CONSUMPTION: 'Parsel-Kuyu Tüketim Raporu',
				CROP_IRRIGATION: 'Mahsül Sulama Raporu',
				INCOME_EXPENSE: 'Gelir Gider Raporu',
				IRRIGATION_HISTORY: 'Sulama Tarihçesi',
				DESCRIPTIONS:{
					WELL_PRODUCTION:'Bu raporda, kooperatife ait tüm kuyuların ve hidrantların veya seçilen kuyunun/hidrantın yıllara ve aylara göre aktif sulama süresi,sulama miktarı ve sulama için harcanan toplam tutar bilgisi raporlanır.',
                    MEMBER_PRODUCTION:'Bu raporda, seçilen kuyu/hidrant için istenilen yıla ve aya göre sulama süresi, sulama miktarı ve sulama için harcanan toplam tutar üye bazında raporlanır.',
                    FIELD_PRODUCTION:'Bu raporda, seçilen kooperatifin tüm üyelerine ait tüm tarlalar için sulama yaptığı aktif süre, su miktarı ve bu süre içinde harcana toplam tutar bilgisi yıl ve ay bazlı olarak raporlanır.Üye ve tarla bazlı filtreleme yapılırsa bu bilgiler seçilen üye ve tarla için raporlanır.',
                    FIELD_WELL_CONSUMPTION:'Bu raporda, tarla seçimi yaptıktan sonra seçilen tarlaya ait o yıl veya aya göre sulama yaptığı su miktarı, geçirdiği aktif süreyi ve bu süre içinde toplam tutar bilgisi raporlanır.',
                    MEMBER_CONSUMPTION:'Bu raporda seçilen kooperatifin tüm üyelerinin  yıla ve aya göre sulama miktarını, sulama süresi ve sulama için harcanan toplam tutar raporlanır. Ayrıca üye filtreleme özelliği ile üye seçilip o üyeye ait sulama süresi, kullanılan su miktarı ve toplam tutar bilgisi de raporlanır.',
                    MEMBER_CASH:'Bu raporda üyelerin hesaplarında yapılmış olan iade ve yükleme işlemleri tarih bilgisi ve ödeme türüyle sisteme kaydedilir. Sulamayı yapan kişinin yatırdığı ve sulamalarında harcadığı para, kalan bakiye bilgisi, işlem yapılan tarih, sulamanın süresi raporlanır. Makbuzlu işlem ise en sağda makbuz butonuna tıklayarak makbuzlu işleme ait makbuz kesilebilir. Makbuzda tabloda yer alan verilerin yanı sıra makbuz numarası, makbuz metni, il, ilçe ve tarih bilgileri bulunur ve bu bilgilerle birlikte yazdırılabilir.',
                    INCOME_EXPENSE:'Bu raporda, yıla veya aya göre toplam gelir ve giderleri raporlanır. Raporda yer alan gider bilgisi seçilen yıl/ay içinde sulamalarınızda harcadığınız tutarı gösterir. Sisteme sulamalar için yüklenilen toplam miktar ise gelir kısmında gösterilir. Ayrıca raporda yer alan net kısmında kâr-zarar miktarınızı öğrenebilirsiniz.',
                    IRRIGATION_HISTORY:'Bu raporda her bir kuyu/hidrant için tüm zamanlarda hangi üyenin hangi kuyudan/hidranttan sulama yaptığı tarih, saat ve dakika bilgileri, aktif sulama süreleri ve üye isimleri ile raporlanır. Ayrıca rapor içinde yıla ve aya göre filtreleme de yapılabilir.',
				}
			},
			FORMS: {
				MAIN: 'Formlar',
				RECEIPT_BOOK: 'Makbuz Defteri',
				IRRIGATION_FORM: 'Sulama Formları',
				MONTHLY_SUMMARY: 'Aylık Döküm',
			},
			SETTINGS: {
				MAIN: 'Ayarlar',
				SMS_SETTINGS: 'SMS Ayarları',
				EMAIL_SETTINGS: 'E-Posta Ayarları'
			},
			QUICKACTIONS: {
				MAIN: 'Hızlı Eylemler',
				SHORTCUTS: 'Kısayollar',
				MEMBER_MANAGEMENT: 'Kooperatif Üyeleri',
				FIELD_MANAGEMENT: 'Tarlalar',
				WELL_MANAGEMENT: 'Kuyular',
			},
			USER_PROFILE: {
				MY_PROFILE: 'Profilim',
			},
			CROPS: 'Mahsül Tanımları',
			FAQ: 'Sıkça Sorulan Sorular',
			ANNOUNCEMENTS: 'Duyurular'
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Tamam',
				NO_ACCOUNT: 'Don\'t have an account?',
				SIGNUP_BUTTON: 'Signup',
				FORGOT_BUTTON: 'Şifremi Unuttum',
				BACK_BUTTON: 'Geri',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Giriş Yapın',
				BUTTON: 'Giriş',
				USERNAME: 'Kullanıcı Adı',
				PASSWORD: 'Şifre',
			},
			FORGOT: {
				TITLE: 'Şifrenizi mi unuttunuz?',
				DESC: 'Şifrenizi sıfırlamak için e-posta ya da telefonunuzu girin',
				SUCCESS_EMAIL: 'Şifrenizi sıfırlamanız için gerekli talimatlar e-posta adresinize gönderildi',
				SUCCESS_PHONE: 'Şifrenizi sıfırlamanız için gerekli olan kod telefonunuza gönderildi',
				SUCCESS: 'Şifrenizi sıfırlayabilirsiniz',
				USER_NOT_FOUND: 'Kişi bulunamadı',
				NEW_CODE_BUTTON: 'Yeni Kod İste',
				TIME: 'Süreniz Doldu',
				EMAIL: 'E-Posta'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login.'
			},
			INPUT: {
				EMAIL: 'E-Posta',
				FULLNAME: 'Ad Soyad',
				PASSWORD: 'Şifre',
				CONFIRM_PASSWORD: 'Şifreyi Onayla',
				PHONE: 'Telefon numarası',
				IDENTITYNUMBER: 'TC Kimlik Numarası',
				REGISTRATIONNUMBER: 'Kayıt Numarası',
			},
			VALIDATION: {
				INVALID: 'Geçersiz {{name}}',
				REQUIRED: '{{name}} zorunludur',
				MIN_LENGTH: '{{name}} için minimum uzunluk {{min}} dir',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'İstenilen {{name}} bulunamadı',
				INVALID_LOGIN: 'Giriş bilgileri yanlış'
			},
			LOGOUT: {
				BUTTON: 'Çıkış'
			},
			DELETE_ACCOUNT: 'Hesabımı Sil'
		},
		IRRIMET: {
			COPYRIGHT: 'Irrimet Sulama Otomasyon Sistemi',
			ANNOUNCEMENT: {
				CREATE: 'Yeni Duyuru',
				UPDATE: 'Duyuruyu Güncelle',
				TITLE : 'Başlık',
				ENTER_TITLE : 'Lütfen başlığı girin',
				ENTER_ANNOUNCEMENT : 'Lütfen duyuruyu girin',
				ANNOUNCEMENT: 'Duyuru',
				ANNOUNCEMENTS: 'Duyurular',
				ACTIVE: 'Aktif',
				START_DATE: 'Başlangıç Tarihi',
				END_DATE: 'Bitiş Tarihi',
				ADD_MESSAGE: 'Duyuru oluşturuldu',
				UPDATE_MESSAGE: 'Duyuru güncellendi',
				CONFIRM_DELETE_TITLE: 'Duyuru İptali Onayı',
				CONFIRM_DELETE_BODY: 'Duyuruyu iptal etmek istiyor musunuz?',
				DELETE_FAILURE: 'Duyuru silinemedi!',
				DELETE_SUCCESS: 'Duyuru silindi',
				DELETE: 'Duyuruyu Sil',
				VIEW: 'Duyuruyu İncele',
				EDIT_BY: 'Düzenleyen',
				EDIT_DATE: 'Düzenleme Tarihi',
				SCOPE: 'Kapsam',
				SYSTEM: 'Sistem',
				DIRECTORATE: 'Kurum',
				COOPERATIVE: 'Kooperatif',
				TARGET: 'Hedef',
				EVERYBODY: 'Herkes',
			},
			ALARMS: {
				LIST: 'Alarm Listesi'
			},
			EVENTS: {
				LIST: 'Olay Listesi'
			},
			COMMON: {
				ERROR: 'Hata',
				SELECTED_RECORDS_COUNT: 'Seçili kayıt sayısı: ',
				ALL: 'Hepsi',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Aktif',
				FILTER: 'Filtre',
				CLEAR_FILTER: 'Filtreyi Temizle',
				BY_STATE: 'Durum',
				BY_TYPE: 'Tip',
				SEARCH: 'Ara',
				IN_ALL_FIELDS: 'Tüm alanlarda (en az 3 harf)',
				ACTIONS: 'Eylemler',
				PLEASE: 'Lütfen',
				NAME: 'Ad',
				SURNAME: 'Soyad',
				NAMEANDSURNAME: 'Ad ve Soyad',
				DISPLAYNAME: 'Ekran İsmi',
				CREDIT_LIMIT: 'Kredi Limiti',
				ON_DEMAND_LIMIT: 'Limit (saat)',
				ON_DEMAND_REMOTE_RFID_CONTROL: 'Kart okutma duraklatsın',
				DIRECTORATE: 'Kurum',
				COOP: 'Kooperatif',
				WELL: 'Kuyu',
				HYDRANT: 'Hidrant',
				PARCEL: 'Parsel',
				MEMBER: 'Üye',
				TOKEN: 'Token',
				RFID: 'RFID',
				BALANCE: 'Bakiye (TL)',
				IDENTITYNUMBER: 'TC Kimlik No',
				REGISTRATIONNUMBER: 'Kayıt Numarası',
				PHONE: 'Telefon',
				EMAIL: 'E-Posta',
				ADDRESS: 'Adres',
				FIRMNAME: 'Firma Adı',
				TAXNUMBER: 'Vergi Numarası',
				TAXOFFICE: 'Vergi Dairesi',
				DESCRIPTION: 'Açıklama',
				DESCRIPTION_SHORT: 'Açıklama',
				ENTER_NAME: 'İsmi giriniz',
				CITY: 'İli',
				CITY_UC : 'İLİ',
				DISTRICT: 'İlçesi',
				DISTRICT_UC: 'İLÇESİ',
				ENTER_DESCRIPTION: 'Açıklamayı giriniz',
				ENTER_SURNAME: 'Soyadı giriniz',
				ENTER_ADDRESS: 'Adres giriniz',
				ENTER_PHONE: 'Tel no giriniz',
				ENTER_EMAIL: 'E-Posta giriniz',
				ENTER_IDENTITYNUMBER: 'TC kimlik no giriniz',
				ENTER_REGISTRATIONNUMBER: 'Kayıt numarası giriniz',
				ENTER_DISPLAYNAME: 'Ekran ismi giriniz',
				ENTER_FIRMNAME: 'Firma adını giriniz',
				ENTER_TAXNUMBER: 'Vergi no giriniz',
				ENTER_TAXOFFICE: 'Vergi dairesini giriniz',
				ENTER_CREDIT_LIMIT: 'Kredi limitini giriniz',
				ENTER_ON_DEMAND_LIMIT: 'Anında sulama limitini giriniz (saat)',
				OK: 'Tamam',
				CANCEL: 'İptal',
				YES: 'Evet',
				NO: 'Hayır',
				CANCEL_CHANGES: 'Değişiklikleri iptal et',
				SAVE: 'Kaydet',
				SAVE_CHANGES: 'Değişiklikleri kaydet',
				DELETE: 'Sil',
				IS_REQUIRED: 'zorunludur',
				NO_RECORDS_FOUND: 'Hiç Kayıt Bulunamadı',
				FORM_ERROR_ALERT: 'Lütfen formdaki eksikleri düzeltip tekrar deneyin',
				TODAY: 'Bugün',
				PREVIOUS: 'Önceki',
				NEXT: 'Sonraki',
				YEAR: 'Yıl',
				MONTH: 'Ay',
				WEEK: 'Hafta',
				DAY: 'Gün',
				HOUR: 'saat',
				HOUR_LOWER: 'saat',
				HOUR_SHORT: 'saat',
				MINUTE: 'dakika',
				MINUTE_SHORT: 'dak',
				SECOND: 'saniye',
				SECOND_SHORT: 'sn',
				START_DATE: 'Verilme Tarihi',
				END_DATE: 'İptal Tarihi',
				STATUS_TOKEN: 'Durum',
				SELECT_YEAR: 'Lütfen yıl seçin',
				SELECT_MONTH: 'Lütfen ay seçin',
				JANUARY: 'Ocak',
				FEBRUARY: 'Şubat',
				MARCH: 'Mart',
				APRIL: 'Nisan',
				MAY: 'Mayıs',
				JUNE: 'Haziran',
				JULY: 'Temmuz',
				AUGUST: 'Ağustos',
				SEPTEMBER: 'Eylül',
				OCTOBER: 'Ekim',
				NOVEMBER: 'Kasım',
				DECEMBER: 'Aralık',
				DATE: 'Tarih',
				INDIVIDUAL: 'Bireysel',
				CORPORATE: 'Kurumsal',
				IRRIGATION_NORMAL: 'Sulama',
				IRRIGATION_ON_DEMAND: 'Anında Sulama',
				RESERVATION_LIST: 'Rezervasyon Listesi',
				REMAINING: 'Kalan',
				ESTIMATED_END: 'Bitiş',
				MAX_ESTIMATED_END: 'Azami Bitiş',
				RESERVATION: 'Rezervasyon',
				TAXNUMBER_VALIDATION_ERR: 'Lütfen geçerli bir vergi numarası giriniz',
				OR: 'ya da',
				PERCENT: 'Yüzde',
				WELL_HIDRANT: 'Kuyu/Hidrant',
				CALENDAR: 'Takvim',
				EDIT: 'Güncelle',
				VOLUME: 'Hacim',
				TIME: 'Zaman',
				NO_DATA: 'Veri Yok',
				PARAMETER: 'Parametre',
				PARAMETERS: 'Parametreler',
				TOTAL_BALANCE: 'Bakiye',
				AVAILABLE_BALANCE: 'Kullanılabilir Bakiye',
				AVAILABLE_BALANCE_SHORT: 'Kullanılabilir',
				TOTAL: 'Yekün'

			},
			ANNOUNCEBOARD: {
				TITLE: 'Duyuru Panosu',
			},
			DASHBOARD: {
				TITLE: 'Durum Panosu',
				STATE_COMMUNICATIONERROR: 'İletişim Hatası',
				STATE_IDLE: 'Beklemede',
				STATE_WAITINGFORCARDREAD: 'Kart Okutma Bekleniyor',
				STATE_IRRIGATING: 'Sulanıyor',
				STATE_IRRIGATIONABOUTTOFINISH: 'Sulama Bitiyor',
				STATE_IRRIGATIONCOMPLETE: 'Sulama Tamamlandı',
				STATE_PAUSED: 'Duraklatıldı',
				STATE_FIRMWAREUPDATE: 'Yazılım Güncelleniyor',
				ALARM: 'Alarm',
				MORE_PERSON: 'kişi daha...',
			},
			DIRECTORATES: {
				DIRECTORATES: 'Kurumlar',
				DIRECTORATES_LIST: 'Kurum Listesi',
				NEW_DIRECTORATE: 'Yeni Kurum',
				EDIT_DIRECTORATE: 'Kurum Güncelle',
				CREATE_NEW_DIRECTORATE: 'Yeni Kurum Oluştur',
				DELETE_DIRECTORATE_SIMPLE: {
					TITLE: 'Kurumu Sil',
					DESCRIPTION: 'Bu kurumu silmek istediğinize emin misiniz?',
					WAIT_DESCRIPTION: 'Kurum siliniyor...',
					MESSAGE: 'Kurum silindi'
				},
				DELETE_DIRECTORATE_MULTY: {
					TITLE: 'Kurumları Sil',
					DESCRIPTION: 'Seçili kurumları silmek istediğinize emin misiniz?',
					WAIT_DESCRIPTION: 'Kurumlar siliniyor...',
					MESSAGE: 'Seçili kurumlar silindi'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Kurum güncellendi',
					ADD_MESSAGE: 'Kurum oluşturuldu'
				}
			},
			COOPS: {
				COOPS: 'Kooperatifler',
				COOPS_LIST: 'Kooperatif Listesi',
				NEW_COOP: 'Yeni Kooperatif',
				EDIT_COOP: 'Kooperatif Güncelle',
				SETTING_COOP: 'Kooperatif Ayarları',
				COOP_CREDITCARD_RATES: 'Kredi Kartı Komisyon Oranları',
				CREATE_NEW_COOP: 'Yeni Kooperatif Oluştur',
				SELECT_DIRECTORATE: 'Kurum seçin',
				DELETE_COOP_SIMPLE: {
					TITLE: 'Kooperatifi Sil',
					DESCRIPTION: 'Bu kooperatifi silmek istediğinize emin misiniz?',
					WAIT_DESCRIPTION: 'Kooperatif siliniyor...',
					MESSAGE: 'Kooperatif silindi'
				},
				DELETE_COOP_MULTY: {
					TITLE: 'Kooperatifleri Sil',
					DESCRIPTION: 'Seçili kooperatifleri silmek istediğinize emin misiniz?',
					WAIT_DESCRIPTION: 'Kooperatifler siliniyor...',
					MESSAGE: 'Seçili kooperatifler silindi'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Kooperatif güncellendi',
					ADD_MESSAGE: 'Kooperatif oluşturuldu'
				},
				SETTING: {
					UPDATE_MESSAGE: 'Kooperatif ayarları güncellendi',
					SELECT_IRRI_REQUEST_TYPE: 'Sulama tipi seçin',
					SELECT_PUMPPRICE_TYPE: 'Fiyatlandırma tipi seçin',
					SELECT_IRRI_EXTEND_INTERVAL: 'Sulama süresi uzatma dakikasını seçin',
					SELECT_MAX_IRRI_EXT_PERCENT: 'Sulama süresinin % kaç uzatılabileceğini seçin',
					FARMER_RESERVATION: 'Çiftçi rezervasyon yapabilsin',
					FARMER_CAN_EXTEND: 'Çiftçi sulamayı uzatabilsin',
					COOP_USER_RESERVATION: 'Koop kullanıcısı rezervasyon yapabilsin',
					COOP_USER_CAN_EXTEND: 'Koop kullanıcısı sulamayı uzatabilsin',
					UPDATE_MESSAGE_ERR: 'Kooperatif ayarları güncellenemedi',
					DIFF_MEMBER_MINUTES_BEFORE_FINISH: 'Farklı üye otomatik devam için min kalan süre',
					SAME_MEMBER_MINUTES_BEFORE_FINISH: 'Aynı üye otomatik devam için min kalan süre',
					TRANSACTION_ON_CREDIT: 'Kredili işlem',
					USE_MAXTIMESTAMP: 'Zaman Limiti',
					FARMER_CAN_CANCEL_RESERVATION: 'Çiftçi rezervasyon iptal edebilsin',
					FARMER_CAN_STOP_IRRIGATION: 'Çiftçi sulama sonlandırabilsin',
					COOP_USER_CANCEL_RESERVATION: 'Koop kullanıcısı rezervasyon iptal edebilsin',
					COOP_USER_STOP_IRRIGATION: 'Koop kullanıcısı sulama sonlandırabilsin',
					ON_DEMAND: 'Anında sulama',
					VALIDATION_EXT_FIELD: '1 ile 10 arası bir değer giriniz.',
					VALIDATION_CREDIT_LIMIT: 'Geçerli bir kredi limiti giriniz.',
					VALIDATION_ON_DEMAND_LIMIT: 'Geçerli bir sulama limiti giriniz.',
					PERSONAL: 'Bireysel',
				}
			},
			CREDIT_CARD_RATE: {
				LIST: 'Kredi Kartı Komisyon Oranları',
				ADD_NEW: 'Yeni Ekle',
				NAME: 'Başlık',
				ENTER_NAME: 'Ör: Ziraat %2, Halkbank %3 gibi',
				RATE: 'Kart Komisyon Oranı (%)',
				NEW_CREDIT_CARD_RATE: 'Yeni Komisyon Oranı',
				ENTER_RATE: '0-100 arasında bir değer giriniz',
				RATE_ERROR: 'Hatalı komisyon oranı',
				DELETE: {
					TITLE: 'Komisyon Oranı Sil',
					DESCRIPTION: 'Bu oranı silmek istediğinize emin misiniz?',
					WAIT_DESCRIPTION: 'Oran siliniyor...',
					MESSAGE: 'Oran silindi'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Oran güncellendi',
					ADD_MESSAGE: 'Oran oluşturuldu',
				},
				WITHDRAW_DESCRIPTON: '{{rNb}} makbuz numaralı, {{amount}} TL ödeme için %{{rate}} kredi kartı komisyonu'
			},
			TOKENS: {
				NEW_TOKEN: 'Yeni Token',
				EDIT_TOKEN: 'Durum Değiştir',
				CHANGE_COOP: 'Koop Değiştir',
				CREATE_NEW_TOKEN: 'Yeni Token Oluştur',
				TOKENS_LIST: 'Token Listesi',
				TOKEN: 'Token Tanımla',
				DELETE_TOKEN_SIMPLE: {
					TITLE: 'Tokeni Sil',
					DESCRIPTION: 'Bu tokeni silmek istediğinize emin misiniz?',
					WAIT_DESCRIPTION: 'Token siliniyor...',
					MESSAGE: 'Token silindi'
				},
				REMOVE_TOKEN_FROM_MEMBER: {
					TITLE: 'Tokeni Geri Al',
					DESCRIPTION: 'Bu tokeni üyeden almak istediğinize emin misiniz?',
					WAIT_DESCRIPTION: 'Token geri alınıyor...',
					MESSAGE: 'Token geri alındı',
					ERROR: 'Token geri alınamadı'

				},
				ASSIGN_TOKEN_TO_MEMBER: {
					TITLE: 'Token Ata',
					SELECT_TOKEN: 'Token seçiniz',
					ASSIGNED_MESSAGE: 'Token atandı',
					NO_TOKEN_LEFT_MESSAGE: 'Kooperatife tanımlanmış tüm tokenler kullanımdadır',
				},
				DELETE_TOKEN_MULTY: {
					TITLE: 'Tokenleri Sil',
					DESCRIPTION: 'Seçili tokenleri silmek istediğinize emin misiniz?',
					WAIT_DESCRIPTION: 'Tokenler siliniyor...',
					MESSAGE: 'Seçili tokenler silindi'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Token güncellendi',
					ADD_MESSAGE: 'Token oluşturuldu',
				},
				TOKEN_STATUS_PASSIVE: 'Boşta',
				TOKEN_STATUS_ACTIVE: 'Kullanımda',
				TOKEN_STATUS_OUT_OF_ORDER: 'Bozuk',
				TOKEN_STATUS_MISSING: 'Kayıp',
				TOKEN_STATUS_RETURN: 'İade',
				TOKENS_EXIST: '{unsavedRfids} tokenleri sistemde mevcuttur',
				CHANGE_DATE: 'Değişiklik Tarihi',
				LAST_READ_DATE_RFID: 'Son Okutma',
				ENTER_TOKEN: 'Tokenleri giriniz',
				STATUS_SELECT: 'Değişiklik nedenini seçiniz',
				LAST_READ_RESULT: {
					TITLE: 'Son Okutma Sonucu',
					BUSY: 'Sunucu Meşgul',
					UNKNOWN: 'Tanınmıyor',
					UNASSIGNED: 'Atanmamış',
					DISABLED: 'Pasif',
					NO_MEMBERSHIP: 'Üyelik Bulunamadı',
					NO_TASK: 'Görev Bulunmuyor',
					MULTIPLE_TASKS: 'Birden Çok Görev',
					INSUFF_BALANCE: 'Yetersiz Bakiye',
					TOO_EARLY: 'Kesintisiz İçin Erken',
				}
			},
			MEMBERS: {
				MEMBERS: 'Üyeler',
				MEMBERS_LIST: 'Üye Listesi',
				NEW_MEMBER: 'Yeni Üye',
				OUTSIDER: 'Kooperatif Dışı Üye',
				EDIT_MEMBER: 'Üye Güncelle',
				CREATE_NEW_MEMBER: 'Yeni Üye Oluştur',
				SELECT_DIRECTORATE: 'Kurum seçin',
				SELECT_COOP: 'Kooperatif seçin',
				MEMBER_INFO: 'Üye Bilgisi',
				MEMBER_RESERVATIONS: 'Üye Rezervasyonları',
				DELETE_MEMBER_SIMPLE: {
					TITLE: 'Üyeyi Sil',
					DESCRIPTION: 'Bu üyeyi silmek istediğinize emin misiniz?',
					WAIT_DESCRIPTION: 'Üye siliniyor...',
					MESSAGE: 'Üye silindi'
				},
				DELETE_MEMBER_MULTY: {
					TITLE: 'Üyeleri Sil',
					DESCRIPTION: 'Seçili üyeleri silmek istediğinize emin misiniz?',
					WAIT_DESCRIPTION: 'Üyeler siliniyor...',
					MESSAGE: 'Seçili üyeler silindi'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Üye güncellendi',
					ADD_MESSAGE: 'Üye oluşturuldu'
				},
				EMPTY_COOP_ERR: 'Lütfen bir kooperatif seçin',
				CONFIRM_CREATE_USER: {
					TITLE : 'Kullanıcı Oluşturma Onayı',
					BODY : 'Oluşturduğunuz üye için kullanıcı oluşturmak ister misiniz?'
				}
			},
			CASHACCOUNT: {
				MEMBER_DEPOSIT: 'Ödeme Yap',
				MEMBER_WITHDRAW: 'Gider Ekle',
				PAYMENT_TYPE: 'Ödeme türü',
				SELECT_PAYMENT_TYPE: 'Ödeme türünü seçin',
				EXPENSE_TYPE: 'Gider tipi',
				SELECT_EXPENSE_TYPE: 'Gider tipini seçin',
				ENTER_OPERATION_DATE: 'İşlem tarihini seçin',
				ENTER_DEPOSIT_AMOUNT: 'Ödeme miktarını giriniz',
				ENTER_WITHDRAW_AMOUNT: 'Gider miktarını giriniz',
				AMOUNT_VALIDATION_ERR: 'Lütfen doğru formatlanmış miktar giriniz',
				DEPOSIT: 'Ödeme başarıyla yapıldı',
				WITHDRAW: 'Gider başarıyla eklendi',
				INVALID_WITHDRAW: 'Mevcut bakiyeniz ({{cash}}) TL den daha fazla bir iade miktarı isteyemezsiniz',
			},
			RESERVATION: {
				TITLE: 'Rezervasyon ve Sulama',
				DAY: 'Gün',
				PARCEL: 'Parsel',
				MEMBER: 'Üye',
				QUANTITY: 'Miktar',
				DURATION: 'Süre',
				STATUS: 'Durum',
				SELECT_DIRECTORATE: 'Lütfen kurum seçin',
				SELECT_COOP: 'Lütfen kooperatif seçin',
				SELECT_WELL: 'Lütfen kuyu/hidrant seçin',
				SELECT_MEMBER: 'Lütfen üye seçin',
				SELECT_PARCEL: 'Lütfen tarla seçin',
				NEW_IRRIGATION: 'Yeni Sulama',
				NEW_IRRIGATION_TOOLTIP: 'Yeni sulama başlat',
				NEW_RESERVATION: 'Yeni Rezervasyon',
				NEW_RESERVATION_TOOLTIP: 'Yeni rezervasyon oluştur',
				IRRIGATION_REQUEST: 'Sulama İsteği',
				IRRIGATION_TIME: 'Sulama süresi',
				IRRIGATION_QUANTITY: 'Sulama miktarı',
				ENTER_IRRIGATION_TIME: 'Lütfen sulama süresini girin',
				ENTER_IRRIGATION_QUANTITY: 'Lütfen sulama miktarını girin',
				CREATE_RES_TITLE: 'Rezervasyonu Oluştur',
				CREATE_IRRIG_TITLE: 'Sulama Başlat',
				CONFIRM_CANCELIRRIGATION_TITLE: 'Sulama İptali Onayı',
				CONFIRM_CANCELRESERVATION_TITLE: 'Rezervasyon İptali Onayı',
				// tslint:disable-next-line:max-line-length
				CONFIRM_CANCELRESERVATION_BODY: '{{wellName}} için {{day}} gününe yapılan {{quantity}} m3 sulama rezervasyonunu iptal etmek istiyor musunuz?',
				// tslint:disable-next-line:max-line-length
				CONFIRM_CANCELIRRIGATION_BODY: '{{wellName}} için {{day}} gününe yapılan {{quantity}} m3 sulamayı iptal etmek istiyor musunuz?',
				CONFIRM_DECREASE_IRRIGATION_TITLE: 'Sulama Miktarını Azaltma Onayı',
				CONFIRM_DECREASE_IRRIGATION_BODY: '{{wellName}} için sulama miktarını {{irrigationExtendInterval}} dakika azaltmak istiyor musunuz?',
				CONFIRM_INCREASE_IRRIGATION_TITLE: 'Sulama Miktarını Arttırma Onayı',
				CONFIRM_INCREASE_IRRIGATION_BODY: '{{wellName}} için sulama miktarını {{irrigationExtendInterval}} dakika arttırmak istiyor musunuz?',
				CONFIRM_PAUSE_IRRIGATION_TITLE: 'Sulama Duraklatma Onayı',
				CONFIRM_PAUSE_IRRIGATION_BODY: '{{wellName}} için sulamayı duraklatmak istiyor musunuz?',
				CONFIRM_RESUME_IRRIGATION_TITLE: 'Sulama Devam Etme Onayı',
				CONFIRM_RESUME_IRRIGATION_BODY: '{{wellName}} için sulamaya devam etmek istiyor musunuz?',
				// tslint:disable-next-line:max-line-length
				CONFIRM_REMOTE_RFID_SCAN_TITLE: 'Uzaktan Kart Okutma Onayı',
				CONFIRM_REMOTE_RFID_SCAN_BODY: '{{wellName}} için uzaktan kart okutmayı başlatmak istiyor musunuz?',
				// tslint:disable-next-line:max-line-length
				CONFIRM_STOP_IRRIGATION_TITLE: 'Sulama Sonlandırma Onayı',
				CONFIRM_STOP_IRRIGATION_BODY: '{{wellName}} için sulamayı sonlandırmak istiyor musunuz?',
				CONFIRM_DELETE_ACTIVE_IRRIGATION_TITLE: 'Aktif Sulama Sonlandırma Onayı',
				CONFIRM_DELETE_ACTIVE_IRRIGATION_BODY: '{{wellName}} için aktif sulamayı sonlandırmak istiyor musunuz?',
				SET_TO_IDLE: 'Beklemeye al',
				REMOTE_RFID_SCAN: 'Kart okut',
				START_IRRIGATION: 'Başlat',
				CANCEL_IRRIGATION: 'İptal et',
				STOP_IRRIGATION: 'Sonlandır',
				PAUSE_IRRIGATION: 'Duraklat',
				INCREASE_QTY: 'dakika arttır',
				DECREASE_QTY: 'dakika azalt',
				RESUME_IRRIGATION: 'Devam et',
				CANCEL_RESERVATION: 'Rezervasyonu kaldır',
				IRRIGATION_PARTNERS: 'Sulama Ortakları',
				ENTER_IRRIGATION_PERCENT: 'Sulama yüzdesini giriniz',
				SINGLE_MEMBER_IRRIGATION: 'Tek Kişilik Sulama',
				MULTIPLE_MEMBER_IRRIGATION: 'Paylaşımlı Sulama',
				EDIT_IRRIGATION: 'Güncelle',
				EDIT_IRRIGATION_TITLE: 'Sulama Güncelle',
				EDIT_IRRIGATION_SUCCESS: 'Sulama güncellendi',
				EDIT_IRRIGATION_FAILURE: 'Sulama güncellenemedi',
				MOVE_UP: 'Yukarı Taşı',
				MOVE_DOWN: 'Aşağı Taşı',
				NO_PRICE_DEFINED: 'Bu kuyu için fiyat tanımı yapılmamış',
				MOVE_ERR: 'Rezervasyon sırası değiştirilmeye uygun değil',
				LAST_COMM_TIME: 'bağlantı',
				RFID_READ_TIME: 'kart okutma',
				LAST_TIMECOUNT_REPORTED: 'sulama',
				NONE_FOUND: 'Hiç Rezervasyon Bulunamadı',
				START_ON_DEMAND_IRRIGATION: 'Anında Sulama Başlat',
				START_ON_DEMAND_IRRIGATION_TOOLTIP: 'Anında sulama oluştur ve başlat',
			},
			RESERVATION_MSGS: {
				SELECT_MEMBER: 'Bir üye seçmelisiniz',
				SELECT_FIELD: 'Bir tarla seçmelisiniz',
				SELECT_WELL_FIRST: 'Önce bir kuyu seçmelisiniz',
				TODAY_HAS_RESERVATIONS: 'Bu kuyuda başka rezervasyonlar olduğu için sulama başlatamazsınız',
				FARMERS_CANNOT_START_IRRIGATION: 'Üyeler sulama başlatamaz!',
				IRRIGATION_REQUEST_SUCCESS: 'Sulama isteği başarıyla iletildi',
				IRRIGATION_REQUEST_FAILURE: 'Sulama isteği başarısız!',
				SET_TO_IDLE_SUCCESS: 'Rezervasyon beklemeye alındı',
				SET_TO_IDLE_FAILURE: 'Rezervasyon beklemeye alınamadı!',
				REMOTE_RFID_SCAN_SUCCESS: 'Uzaktan kart okutuldu',
				REMOTE_RFID_SCAN_FAILURE: 'Uzaktan kart okutulamadı!',
				CANCEL_IRRIGATION_SUCCESS: 'Sulama isteği iptal edildi',
				CANCEL_IRRIGATION_FAILURE: 'Sulama isteği iptal edilemedi!',
				CANCEL_RESERVATION_SUCCESS: 'Rezervasyon iptal edildi',
				CANCEL_RESERVATION_FAILURE: 'Rezervasyon iptal edilemedi!',
				STOP_IRRIGATION_SUCCESS: 'Sulama sonlandırıldı',
				STOP_IRRIGATION_FAILURE: 'Sulama sonlandırılamadı!',
				PAUSE_IRRIGATION_SUCCESS: 'Sulama duraklatıldı',
				PAUSE_IRRIGATION_FAILURE: 'Sulama duraklatılamadı!',
				RESUME_IRRIGATION_SUCCESS: 'Sulamaya devam ediliyor',
				RESUME_IRRIGATION_FAILURE: 'Sulama devam ettirilemedi!',
				INCREASE_QTY_SUCCESS: 'Sulama miktarı arttırıldı',
				INCREASE_QTY_FAILURE: 'Sulama miktarı arttırılamadı!',
				DECREASE_QTY_SUCCESS: 'Sulama miktarı azaltıldı',
				DECREASE_QTY_FAILURE: 'Sulama miktarı azaltılamadı!',
				RESERVATION_CREATED: 'Rezervasyon oluşturuldu',
				INVALID_BALANCE_QTY: 'Yetersiz bakiye. {{member}} (mevcut: {{creditInVolume}} m3, gerekli: {{requiredCreditInVolume}} m3)',
				INVALID_BALANCE_TIME: 'Yetersiz bakiye. {{member}} (mevcut: {{creditInHours}} saat, gerekli: {{requiredCreditInHours}} saat)',
				INVALID_QTY: 'Sulama miktarı sıfırdan büyük olmalı',
				INVALID_TIME: 'Sulama süresi sıfırdan büyük olmalı',
				TOTAL_PERCENT_MUST_BE_100: 'Toplam sulama paylaşımı %100 olmalı',
				SELECT_IRRIGATION_PARTNERS: 'Sulama ortaklarını seçiniz',
				ENTER_IRRIGATION_AMOUNT: 'Sulama süresi/miktarını giriniz',
				SELECT_ONE_VALVE: 'En az bir çıkış seçmelisiniz',
				START_ON_DEMAND_IRRIGATION_FAILURE: 'Bu kuyuda anında sulama başlatamazsınız',
				DELETE_ACTIVE_IRRIGATION_SUCCESS: 'Aktif sulama silindi',
				DELETE_ACTIVE_IRRIGATION_FAILURE: 'Aktif sulama silinemedi!',

			},
			WELLS: {
				WELL_LIST: 'Kuyu Listesi',
				NEW_WELL: 'Yeni Kuyu',
				CREATE_NEW_WELL: 'Yeni Kuyu Oluştur',
				WELL_NAME: 'Kuyu Adı',
				ENTER_WELL_NAME: 'Kuyu adını giriniz',
				IMEI: 'Imei',
				ENTER_IMEI: 'Lütfen imei bilgisini giriniz',
				IMEI_VALIDATION_ERR: 'Imei 15 haneli bir sayı olmalıdır',
				PUMP_ID: 'Pompa No',
				ENTER_PUMP_ID: 'Lütfen pompa no bilgisini giriniz',
				PUMPID_VALIDATION_ERR: 'Pompa numarası zorunlu bir nümerik değerdir',
				HAS_DEBIMETER: 'Debimetre',
				HAS_REMOTE_RFID_SCAN: 'Uzaktan Kart Okuma',
				DEBIMETER_ID: 'Debimetre No',
				ENTER_DEBIMETER_ID: 'Lütfen debimetre no bilgisini giriniz',
				DEBIMETERID_VALIDATION_ERR: 'Debimetre no zorunlu bir nümerik değerdir',
				DEBIMETER_COEF: 'Debimetre Katsayısı',
				ENTER_DEBIMETER_COEF: 'Lütfen debimetre katsayı bilgisini giriniz',
				DEBIMETERCOEF_VALIDATION_ERR: 'Debimetre no zorunlu bir nümerik değerdir',
				FLOW_RATE: 'Debi',
				ENTER_FLOW_RATE: 'Lütfen debi bilgisini giriniz',
				FLOWRATE_VALIDATION_ERR: 'Debi zorunlu bir nümerik değerdir',
				IRRIGATION_REQUEST_TYPE: 'Sulama Tipi',
				OFFLINE: 'Devre Dışı',
				ELEVATION: 'Rakım',
				ENTER_ELEVATION: 'Lütfen rakım bilgisini giriniz',
				ELEVATION_VALIDATION_ERR: 'Geçerli bir rakım bilgisi girin',
				LATITUDE: 'Enlem',
				ENTER_LATITUDE: 'Lütfen enlem bilgisini giriniz',
				LATITUDE_VALIDATION_ERR: 'Geçerli bir enlem bilgisi girin',
				LONGTITUDE: 'Boylam',
				ENTER_LONGTITUDE: 'Lütfen boylam bilgisini giriniz',
				LONGTITUDE_VALIDATION_ERR: 'Geçerli bir boylam bilgisi girin',
				COOP: 'Bağlı Olduğu Kooperatif',
				COOP_MUST_BE_SELECTED_ERR: 'Bağlı olduğu kooperatifi seçmelisiniz',
				PRICE_RATE: 'Fiyat',
				EDIT_WELL: 'Kuyu Güncelle',
				PARAMETERS: 'Kuyu Parametreleri',
				PUMP_PRICE_RATE: 'Pompa Fiyat Tanımı',
				COOP_NAME: 'Kooperatif',
				EDIT: {
					UPDATE_MESSAGE: 'Kuyu güncellendi',
					ADD_MESSAGE: 'Kuyu oluşturuldu'
				},
				CONTINUOUS_IRRIGATION: 'Kesintisiz Sulama',
				ENERGY_ANALYZER: 'Enerji Analizörü',
				SETTINGS: 'Kuyu Ayarları',
				WELL_SETTINGS_DEFAULT_MESSAGE: 'Varsayılan Mesaj',
				WELL_SETTINGS_MESSAGE: 'Kullanılan Mesaj',
				SETTINGS_SAVED: 'Kuyu ayarları kaydedildi',
				GENERAL_SETTINGS: 'Genel Ayarlar',
				ALARM_MESSAGES: 'Alarm Mesajları',
				PRODUCT_ID: 'Donanım',
				FW_VERSION: 'Yazılım',
			},
			DEVICE: {
				STATUS: {
					TITLE: 'Durum',
					UNKNOWN: 'Bilinmiyor',
					INACTIVE: 'Aktif Değil',
					ACTIVE: 'Aktif',
					FIRMWARE_UPDATING: 'Yazılım Güncelleniyor',
					WAITING_TO_SET_TIME: 'Zaman Ayarlama Bekliyor',
					SETTING_TIME: 'Zaman Ayarlanıyor',
					NOT_CONNECTED: 'İletişim Hatası',
				},
			},
			PARAMETERS: {
				TABS: {
					VIEW: 'Parametreler',
					FIRMWARE: 'Yazılım',
					DEVICE: 'Cihaz',
					SERVER: 'Sunucu'
				},
				READ_PARAMETERS: {
					TITLE: 'Parametreleri Oku',
					DESCRIPTION: 'Seçtiğiniz kuyunun parametreleri okunacaktır. Devam etmek istiyor musunuz?',
					WAIT_DESCRIPTION: 'Parametereler okunuyor...',
					MESSAGE: 'Parametereler okundu',
					ERR_MESSAGE: 'Parametereler okunamadı'
				},
				UPDATE_FIRMWARE: {
					TITLE: 'Yazılım Güncellemesi Başlat',
					DESCRIPTION: 'Seçtiğiniz kuyuda yazılım güncellemesi başlatılacaktır . Devam etmek istiyor musunuz?',
					WAIT_DESCRIPTION: 'Yazılım güncellemesi başlatılıyor...',
					MESSAGE: 'Yazılım güncellemesi başlatıldı',
					ERR_MESSAGE: 'Yazılım güncellemesi başlatılamadı'
				},
				SET_TIME: 'Saati belirle',
				FORWARD_TIME: 'Saati ilerlet (sn)',
				SET_TIME_ZONE: 'Saat dilimini belirle (GMT + X dak)',
				SET_AUTO_UPDATE: 'NTP kullanımı',
				LAST_READ_TIME: 'Son okunma zamanı',
				SERVER_IP: 'Sunucu ip adresi',
				KEEP_ALIVE_PERIOD: 'Keep alive süresi',
				LOW_POWER: 'Düşük güç tüketimi',
				SET: 'Uygula',
				MODEM_ON_PERIOD: 'Modem açık süresi',
				MODEM_OFF_PERIOD: 'Modem kapalı süresi',
				LOAD_FACTORY_DEFAULTS: 'Fabrika ayarlarını yükle',
				FORMAT_ARCHIVE: 'Arşiv hafızasını formatla',
			},
			WELL_ENERGY: {
				TITLE: 'Enerji Analizi',
				TABS: {
					BASIC: 'Temel veriler',
					ADVANCED: 'Gelişmiş veriler',
					GRAPHS: 'Grafikler'
				}
			},
			WELL_INPUTS: {
				TITLE: 'Girişler',
				LAST_INPUT: 'Son Veri',
				PREVIOUS_INPUTS: 'Veriler',
				DATE: 'Tarih',
				MAIN_POWER: 'Besleme gerilimi',
				SENSOR_POWER: 'Sensör besleme',
				CPU_TEMPERATURE: 'CPU sıcaklığı',
				DIGITAL_INPUT_1: 'Dijital giriş 1',
				DIGITAL_INPUT_2: 'Dijital giriş 2',
				DIGITAL_INPUT_3: 'Dijital giriş 3',
				DIGITAL_INPUT_4: 'Dijital giriş 4',
				ANALOG_INPUT_1: 'Analog giriş 1',
				ANALOG_INPUT_2: 'Analog giriş 2',
				ANALOG_INPUT_3: 'Analog giriş 3',
				ANALOG_INPUT_4: 'Analog giriş 4',
			},
			WELL_PUMP_PRICE_RATE: {
				TITLE: 'Pompa Maliyeti',
				PRICE_RATE: 'Fiyat',
				PRICE_RATE_TYPE: 'Fiyat Tipi',
				START_DATE: 'Başlangıç Tarihi',
				END_DATE: 'Bitiş Tarihi',
				ACTIONS: 'Eylemler',
				NEW_PUMP_PRICE_RATE: 'Yeni Pompa Maliyeti',
				CREATE_NEW_PUMP_PRICE_RATE: 'Yeni Pompa Maliyeti Tanımla',
				EDIT_PRICE_RATE: 'Pompa Maliyeti Güncelle',
				NEW_PRICE_RATE: 'Yeni Pompa Maliyeti',
				DELETE_PRICE_RATE: {
					DELETE: 'Sil',
					TITLE: 'Pompa Maliyeti Sil',
					DESCRIPTION: 'Seçtiğiniz pompa maliyeti silinecektir. Devam etmek istiyor musunuz?',
					WAIT_DESCRIPTION: 'Pompa maliyeti siliniyor...',
					MESSAGE: 'Pompa maliyeti silindi',
					ERR_MESSAGE: 'Pompa maliyeti silinemedi'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Pompa maliyeti güncellendi',
					ADD_MESSAGE: 'Pompa maliyeti oluşturuldu'
				},
				ENTER_PRICE_TYPE: 'Lütfen bir fiyat tipi seçiniz',
				ENTER_PRICE_RATE: 'Lütfen fiyatı girin. Örnek fiyat formatı 20,25',
				ENTER_START_DATE:'Lütfen Başlangıç Tarihini girin',
				EXTERNAL_FIELD_COEF: 'Saha Dışı Fiyat Katsayısı',
				ENTER_EXTERNAL_FIELD_COEF: 'Lütfen saha dışı fiyat katsayısını giriniz',
				VALIDATION_EXT_FIELD: '1 ile 10 arası bir değer giriniz',
				VALIDATION_START_DATE:'Geçerli bir başlangıç tarihi giriniz.',
				VALIDATION_ERR_PUMP_PRICE_RATE: 'Geçerli bir fiyat giriniz',
			},
			FIELDS: {
				FIELD: 'Tarla',
				SELECT_FIELD: 'Lütfen tarla seçin',
				FIELD_LIST: 'Tarla Listesi',
				NEW_FIELD: 'Yeni Tarla',
				CREATE_NEW_FIELD: 'Yeni Tarla Oluştur',
				FIELD_NAME: 'Tarla Adı',
				ENTER_FIELD_NAME: 'Lütfen tarla girin',
				BLOCK_NUMBER: 'Ada No',
				ENTER_BLOCK_NUMBER: 'Lütfen ada numarasını girin',
				BLOCK_NUMBER_VALIDATION_ERR: 'Ada numarası zorunludur',
				PARCEL_NUMBER: 'Parsel No',
				ENTER_PARCEL_NUMBER: 'Lütfen parsel numarasını girin',
				PARCEL_NUMBER_VALIDATION_ERR: 'Parsel numarası zorunludur',
				DECARES: 'Alan (daa)',
				DECARES_VALIDATION_ERR: 'Alan zorunludur',
				ENTER_DECARES: 'Lütfen alanı girin',
				EXTERNAL_FIELD: 'Saha Dışı',
				EDIT_FIELD: 'Tarla Güncelle',
				EDIT: {
					UPDATE_MESSAGE: 'Tarla güncellendi',
					ADD_MESSAGE: 'Tarla oluşturuldu'
				},
				GENERAL_INFO: 'Genel Bilgiler',
				MEMBER_AND_WELLS: 'Tarla Sahibi ve Kuyular',
				FIELD_OWNER: 'Tarla Sahibi',
				WELLS_THAT_CANBE_USED: 'Kullanılabilecek Kuyular',
				SELECT_FIELD_OWNER: 'Lütfen tarla sahibini seçin',
				SELECT_WELLS_THAT_CANBE_USED: 'Lütfen kullanılabilecek kuyuları seçin',
				POLYGON_COORDINATES: 'Polygon Koordinatları',
				ENTER_POLYGON_COORDINATES: 'Enlem,boylam olarak giriniz',
				POLYGON_VALIDATION_ERR: 'Her satırda bir enlem,boylam giriniz',
				ERROR_IN_POLYGON_COORDINATES: 'Hatalı poligon koordinatları',
				ERROR_AT_LEAST_3_COORDINATES_REQUIRED: 'En az 3 koordinat gereklidir'
			},
			PROFILE: {
				UPDATE_PROFILE: 'Profil Güncelle',
				CHANGE_PASSWORD: 'Şifre Değiştir',
				NAME: 'Ad',
				ENTER_NAME: 'Lütfen adınızı giriniz',
				SURNAME: 'Soyad',
				ENTER_SURNAME: 'Lütfen soyadınızı giriniz',
				PHONE: 'Telefon',
				ENTER_PHONE: 'Lütfen telefon numaranızı giriniz',
				PHONE_ERR: 'Lütfen geçerli bir telefon numarası giriniz',
				EMAIL: 'E-Posta',
				ENTER_EMAIL: 'Lütfen e-postanızı giriniz',
				EMAIL_ERR: 'Lütfen geçerli bir e-posta giriniz',
				IDENTITY_NB: 'TC Kimlik No',
				ENTER_IDENTITY_NB: 'Lütfen TC kimlik numaranızı giriniz',
				IDENTITY_NB_ERR: 'Lütfen geçerli bir TC kimlik no giriniz',
				REGISTRATION_NB_ERR: 'Lütfen geçerli bir kayıt no giriniz',
				HEADER: 'Değiştirmek istediğiniz bilgileri giriniz',
				PASSWORD: 'Eski Şifre',
				ENTER_PASSWORD: 'Eski şifrenizi giriniz (min 6 karakter)',
				PASSWORD_ERR: 'Lütfen eski şifrenizi giriniz (min 6 karakter)',
				NEW_PASSWORD: 'Yeni Şifre',
				ENTER_NEW_PASSWORD: 'Yeni şifrenizi giriniz (min 6 karakter)',
				NEW_PASSWORD_ERR: 'Lütfen yeni şifrenizi giriniz (min 6 karakter)',
				NEW_PASSWORD_R: 'Yeni Şifre Tekrar',
				ENTER_NEW_PASSWORD_R: 'Yeni şifrenizi tekrar giriniz (min 6 karakter)',
				NEW_PASSWORD_R_ERR: 'Şifreler eşleşmiyor lütfen aynı şifreyi girin',
				UPDATE_PASSWORD_SUCCESS: 'Şifre Başarıyla Güncellendi',
				UPDATE_PROFILE_SUCCESS: 'Kullanıcı bilgileri başarıyla güncellendi',
				UPDATE_PROFILE_FAILURE: 'Kullanıcı bilgileri güncellenemedi',
			},
			REPORTS: {
				TOTAL_METER_CUBE: 'Miktar (m3)',
				ACTIVE_DURATION: 'Aktif Süre',
				TOTAL_DURATION: 'Toplam Süre',
				IRRIGATION_DURATION: 'Sulama Süresi',
				TOTAL_SPENT: 'Tutar (TL)',
				DEPOSIT: 'Yatırılan (TL)',
				DEPOSIT_SHORT: 'Y (TL)',
				SPENT: 'Harcanan (TL)',
				SPENT_SHORT: 'H (TL)',
				BALANCE: 'Bakiye (TL)',
				BALANCE_SHORT: 'B (TL)',
				ACTION_TYPE: 'İşlem tipi',
				ACTION_TYPE_SHORT: 'İşlem',
				PAYMENT_TYPE: 'Ödeme türü',
				OPERATOR: 'İşlemi Yapan',
				ACTION_TYPE_LOAD: 'Yükleme',
				ACTION_TYPE_RETURN: 'İade',
				ACTION_TYPE_IRRIGATION: 'Sulama',
				ACTION_TYPE_DEBT: 'Borç/Düzeltme',
				ACTION_TYPE_CREDIT_CARD_COMISSION: 'Kredi Kartı Komisyonu',
				ACTION_TYPE_SERVICE: 'Hizmet',
				PAYMENT_TYPE_CASH : 'Nakit',
				PAYMENT_TYPE_CREDITCARD : 'Kredi Kartı',
				PAYMENT_TYPE_MONEYORDER : 'Banka Havalesi',
				SELECT_ACTION_TYPE: 'İşlem tipini seçin',
				EXTERNAL_INTERNAL_FIELD: 'Saha İçi/Dışı',
				EXTERNAL: 'Saha Dışı',
				INTERNAL: 'Saha İçi',
				PRINT: 'Yazdır',
				WELL: 'Kuyu',
				INCOME: 'Gelir',
				EXPENSE: 'Gider',
				NET: 'Net',
				PERIOD: 'Dönem',
				PAYMENTS: 'Tahsilatlar',
				IRRIGATION_SUMMARY: 'Sulama Dökümü',
				REPORT_DESCRIPTION: 'Rapor Açıklaması',
				REPORT_FOOTER_ID: 'Rapor Id:'
			},
			USERS: {
				USERS: 'Kullanıcılar',
				USERS_LIST: 'Kullanıcı Listesi',
				DIRECTORATE_USER_LIST: 'Kurum Kullanıcı Listesi',
				COOP_USER_LIST: 'Kooperatif Kullanıcı Listesi',
				MEMBER_USER_LIST: 'Üye Kullanıcı Listesi',
				NEW_USER: 'Yeni Kullanıcı',
				EDIT_USER: 'Kullanıcı Güncelle',
				CREATE_NEW_USER: 'Yeni Kullanıcı Oluştur',
				USER_INFO: 'Kullanıcı Bilgisi',
				DIRECTORATE_USER_INFO: 'Kurum Kullanıcı Bilgisi',
				COOP_USER_INFO: 'Kooperatif Kullanıcı Bilgisi',
				MEMBER_USER_INFO: 'Üye Kullanıcı Bilgisi',
				DEPEND_ON_MEMBER: 'Bağlı Olduğu Üye',
				DEPEND_ON_COOP: 'Bağlı Olduğu Kooperatif',
				DEPEND_ON_DIRECTORATE: 'Bağlı Olduğu Kurum',
				USER_PASSWORD: 'Kullanıcı Şifresi',
				ENTER_USERNAME: 'Kullanıcı adını giriniz',
				USERNAME: 'Kullanıcı Adı',
				ADMIN_CHECK: 'Yönetici',
				EDIT_PASSWORD: 'Şifre Güncelle',
				SELECT_DIRECTORATE: 'Kurum Seçin',
				SELECT_COOP: 'Kooperatif Seçin',
				EMPTY_EMAIL: 'Tanımlanmadığı için e-posta yoluyla şifre sıfırlama yapılamayacaktır.',
				EDIT: {
					PASSWORD_MESSAGE: 'Şifre Güncellendi',
					UPDATE_MESSAGE: 'Kullanıcı güncellendi',
					ADD_MESSAGE: 'Kullanıcı oluşturuldu'
				},
			},
			SMS_SETTINGS: {
				TITLE: 'SMS Sağlayıcı Ayarları',
				USERNAME: 'Kullanıcı Adı',
				USERNAME_REQUIRED: 'Kullanıcı adı zorunludur',
				USERNAME_ENTER: 'Lütfen kullanıcı adını girin',
				PASSWORD: 'Şifre',
				PASSWORD_REQUIRED: 'Şifre zorunludur',
				PASSWORD_ENTER: 'Lütfen şifreyi girin',
				SAVE_SUCCESS: 'SMS sağlayıcı ayarları başarıyla kaydedildi',
				SAVE_FAILURE: 'SMS sağlayıcı ayarları kaydedilemedi'

			},
			EMAIL_SETTINGS: {
				TITLE: 'E-Posta Sağlayıcı Ayarları',
				SENDER_ADDRESS: 'Gönderen Adresi',
				SENDER_ADDRESS_REQUIRED: 'Gönderen Adresi zorunludur',
				SENDER_ADDRESS_ENTER: 'Lütfen gönderen adresini girin',
				SENDER_NAME: 'Gönderen Adı',
				SENDER_NAME_REQUIRED: 'Gönderen Adı zorunludur',
				SENDER_NAME_ENTER: 'Lütfen gönderen adını girin',
				SMTP_HOST: 'SMTP Host',
				SMTP_HOST_REQUIRED: 'SMTP Host zorunludur',
				SMTP_HOST_ENTER: 'Lütfen SMTP hostu girin',
				SMTP_PORT: 'SMTP Port',
				SMTP_PORT_REQUIRED: 'SMTP Port zorunludur',
				SMTP_PORT_ENTER: 'Lütfen SMTP portu girin',
				SMTP_USERNAME: 'SMTP Kullanıcı Adı',
				SMTP_USERNAME_REQUIRED: 'SMTP kullanıcı adı zorunludur',
				SMTP_USERNAME_ENTER: 'Lütfen SMTP kullanıcı adını girin',
				SMTP_PASSWORD: 'SMTP Şifresi',
				SMTP_PASSWORD_REQUIRED: 'SMTP Şifresi zorunludur',
				SMTP_PASSWORD_ENTER: 'Lütfen SMTP şifresini girin',
				SAVE_SUCCESS: 'E-Posta sağlayıcı ayarları başarıyla kaydedildi',
				SAVE_FAILURE: 'E-Posta sağlayıcı ayarları kaydedilemedi'
			},
			IRRIGATION_FORM: {
				PARTNER_NO: 'Ortak No',
				DISTRICT: 'Mahalle',
				SHEET_NO: 'Pafta No',
				PARCEL_NO: 'Parsel No',
				WELL_NO: 'Kuyu No',
				POSITION: 'Mevkii',
				PLANT_TYPE: 'Bitki Cinsi',
				EXTENT: 'Sahası (dk)',
				IRRIGATION_START_DATE: 'Sulamanın Başlama Tarihi',
				IRRIGATION_END_DATE: 'Sulamanın Bitiş Tarihi',
				IRRIGATION_START_TIME:  'Sulamanın Başlama Saati',
				IRRIGATION_END_TIME:  'Sulamanın Bitiş Saati',
				IRRIGATION_DURATION: 'Sulama Müddeti (saat)',
				IRRIGATION_AMOUNT: 'Sulama Miktarı (m3)',
				IRRIGATION_FEE: 'Sulama Ücreti (TL)',
				AMOUNT_TO_BE_PAID: 'Ödenecek Tutar (TL)',
				PRINT_TITLE: 'Yazdır',
				PRINT: 'Yazdır',
				USER_SIGN1: 'Su Kullananın',
				USER_SIGN2: 'İmzası',
				CRAFTSMAN_SIGN1: 'Toprak Su Ustasının',
				CRAFTSMAN_SIGN2: 'Adı Soyadı İmzası',
				LABEL1 : 'Ortak Olanlar İçin',
				LABEL2 : 'SULAMA FORMU',
				LABEL3 : 'nci Sulama',
			},
			MONTHLY_SUMMARY_FORM: {
				IRRIGATIONS:'Sulamalar',
				PAYMENTS:'Tahsilatlar',
				COOP_RESPONSIBLE:'Kooperatif yetkilisi',
				NAME:'İsim Soyisim: ',
				SIGN:'İmza: ',
				REPORT_CREATED_DATE:'Rapor oluşturma tarihi: ',
				REPORT_CREATER:'Raporu oluşturan: ',
				IDENTITY:'TC: ',
				DATE:'Tarih: ',
				MEMBER:'Üye:',
				MEMBER_NUMBER:'Üye No: ',
				ERR_MESSAGE:'Girilen zaman aralığı içinde raporlanacak kayıt bulunamamıştır.',
				COOP:'Kooperatif:',
			},
			CROPTYPE: {
				APPLE: 'Elma',
				PEAR: 'Armut',
				APRICOT: 'Kayısı',
				CHERRY: 'Kiraz',
				SOUR_CHERRY: 'Vişne',
				PEACH: 'Şeftali',
				PLUM: 'Erik',
				RICE: 'Çeltik',
				CARROT: 'Havuç',
				CELERIAC: 'Kereviz',
				KOHLRABI: 'Alabaş',
				RADISH: 'Turp',
				TOMATO: 'Domates',
				AUBERGINE: 'Patlıcan',
				PEPPER: 'Biber',
				ONION: 'Soğan',
				SHALLOT: 'Arpacık Soğan',
				LEEK: 'Pırasa',
				GARLIC: 'Sarımsak',
				POTATO: 'Patates',
				BEET: 'Pancar',
				GRAPE: 'Üzüm',
				LEMON: 'Limon',
				ORANGE: 'Portakal',
				TANGERINE: 'Mandalina',
				WHEAT: 'Buğday',
				OAT: 'Yulaf',
				BARLEY: 'Arpa',
				RYE: 'Çavdar',
				MAIZE: 'Mısır',
				ALMOND: 'Badem',
				WALNUT: 'Ceviz',
				HAZELNUT: 'Fındık',
				CUCUMBER: 'Hıyar',
				MELON: 'Kavun',
				SQUASH: 'Kabak',
				PUMPKIN: 'Bal Kabağı',
				WATERMELON: 'Karpuz',
				CABBAGE: 'Lahana',
				ENDIVE: 'Hindiba',
				LETTUCE: 'Marul',
				RAPE_SEED: 'Kanola',
				OLIVE: 'Zeytin',
				STRAWBERRY: 'Çilek',
			},
			CROP: {
				LIST : 'Mahsül Listesi',
				NAME: 'Mahsül İsmi',
				WATER_NEED: 'Su İhtiyacı',
				DEFAULT_WATER_NEED: 'Varsayılan Su İhtiyacı',
				WATER_NEED_VALIDATION_ERR: 'Su ihtiyacı zorunlu bir nümerik değerdir',
				PLANTED_CROPS: 'Ürün Deseni',
				YEAR: 'Yıl',
				ADD_CROP: 'Mahsül Ekle',
				CROP: 'Mahsül',
				SELECT_CROP: 'Lütfen mahsül seçin',
				ENTER_PERCENT: 'Lütfen alan yüzdesini girin',
				ENTER_VALID_PERCENT: 'Alan yüzdesi sıfırdan büyük olmalı',
				INVALID_TOTAL_PERCENT: 'Toplam ekili alan %100 den fazla olamaz',
				INVALID_CROP_PERCENT: 'Mahsül alan yüzdesi sıfırdan büyük olmalıdır',
				SEASON: 'Sezon',
				SELECT_SEASON: 'Lütfen sezon seçin',
				PLANTING_DATE: 'Ekim Tarihi',
				SELECTED_CROPS: 'Seçili Mahsül',
				CROPS_TO_BE_IRRIGATED: 'Sulanacak Mahsül',
				HARVESTED: 'Hasat Edilmiş',
				ESTIMATED_IRRIGATION: 'Tahmini Sulama (m3)',
				ACTUAL_IRRIGATION: 'Gerçekleşen Sulama (m3)',
				NO_CROP_DEF: 'Ekili Mahsül Tanımı Yok',
			},
			RECEIPT: {
				NO: 'No',
				RECEIPT : 'Makbuz',
				RECEIPT_NO : 'Makbuz No',
				RECEIPT_NO_SHORT : 'Makb.',
				PAYMENT_TYPE: 'Ödeme Türü',
				PAYMENT: 'Ödeme',
				BACK_PAYMENT: 'İade',
				CREDIT_CARD_COMMISSION: 'KK Komisyonu',
				DEBT: 'Borç/Düzeltme',
				LATE_FEE: 'Gecikme Zammı',
				CREDIT_YEAR: 'Alacak',
				CREDIT_YEAR1: 'Yılı',
				AMOUNT: 'Yekün',
				AMOUNT1: 'Miktar (TL)',
				AMOUNT_SHORT: 'Miktar',
				FREE_TEXT1: 'Yalnız',
				FREE_TEXT2: 'Lira',
				FREE_TEXT3: 'dan',
				FREE_TEXT3_2: 'e',
				FREE_TEXT4: 'tahsil edilmiştir',
				FREE_TEXT4_2: 'iade edilmiştir',
				FREE_TEXT5: 'adına borç bakiyesi',
				FREE_TEXT5_2: 'olarak yazılmıştır.',
				SIGN1: 'Yevmiye Def. Mad.',
				SIGN2: 'Kasa Görevlisi',
				SIGN3: 'Başkan',
				CONFIRM_PRINT_TITLE: 'Makbuz Bastır',
				CONFIRM_PRINT_DESCRIPTION: 'Makbuz bastırmak istiyor musunuz?',
			},
			HYDRANTS: {
				HYDRANT: 'Hidrant',
				HYDRANT_LIST: 'Hidrant Listesi',
				NEW_HYDRANT: 'Yeni Hidrant',
				CREATE_NEW_HYDRANT: 'Yeni Hidrant Oluştur',
				HYDRANT_NAME: 'Hidrant Adı',
				ENTER_HYDRANT_NAME: 'Hidrant adını giriniz',
				IMEI: 'Imei',
				ENTER_IMEI: 'Lütfen imei bilgisini giriniz',
				IMEI_VALIDATION_ERR: 'Imei 15 haneli bir sayı olmalıdır',
				PUMP_ID: 'Pompa No',
				ENTER_PUMP_ID: 'Lütfen pompa no bilgisini giriniz',
				PUMPID_VALIDATION_ERR: 'Pompa numarası zorunlu bir nümerik değerdir',
				HAS_DEBIMETER: 'Debimetre',
				HAS_REMOTE_RFID_SCAN: 'Uzaktan Kart Okuma',
				DEBIMETER_ID: 'Debimetre No',
				ENTER_DEBIMETER_ID: 'Lütfen debimetre no bilgisini giriniz',
				DEBIMETERID_VALIDATION_ERR: 'Debimetre no zorunlu bir nümerik değerdir',
				VALVE_ID: 'Vana No',
				ENTER_VALVE_ID: 'Lütfen vana no bilgisini giriniz',
				VALVEID_VALIDATION_ERR: 'Vana no zorunlu bir nümerik değerdir',
				DEBIMETER_COEF: 'Debimetre Katsayısı',
				ENTER_DEBIMETER_COEF: 'Lütfen debimetre katsayı bilgisini giriniz',
				DEBIMETERCOEF_VALIDATION_ERR: 'Debimetre no zorunlu bir nümerik değerdir',
				FLOW_RATE: 'Debi',
				ENTER_FLOW_RATE: 'Lütfen debi bilgisini giriniz',
				FLOWRATE_VALIDATION_ERR: 'Debi zorunlu bir nümerik değerdir',
				IRRIGATION_REQUEST_TYPE: 'Sulama Tipi',
				OFFLINE: 'Devre Dışı',
				ELEVATION: 'Rakım',
				ENTER_ELEVATION: 'Lütfen rakım bilgisini giriniz',
				ELEVATION_VALIDATION_ERR: 'Geçerli bir rakım bilgisi girin',
				LATITUDE: 'Enlem',
				ENTER_LATITUDE: 'Lütfen enlem bilgisini giriniz',
				LATITUDE_VALIDATION_ERR: 'Geçerli bir enlem bilgisi girin',
				LONGTITUDE: 'Boylam',
				ENTER_LONGTITUDE: 'Lütfen boylam bilgisini giriniz',
				LONGTITUDE_VALIDATION_ERR: 'Geçerli bir boylam bilgisi girin',
				COOP: 'Bağlı Olduğu Kooperatif',
				COOP_MUST_BE_SELECTED_ERR: 'Bağlı olduğu kooperatifi seçmelisiniz',
				PRICE_RATE: 'Fiyat',
				EDIT_HYDRANT: 'Hidrant Güncelle',
				SETTINGS: 'Hidrant Ayarları',
				PARAMETERS: 'Hidrant Parametreleri',
				PRICE_TARIFF: 'Fiyat Tarifesi',
				COOP_NAME: 'Kooperatif',
				IMEINO_EXIST: 'imei numarası sistemde mevcuttur',
				VALVE: 'Vana',
				ENDPOINT: 'Çıkış',
				CREATE: {
					ADD_MESSAGE: 'Hidrant oluşturuldu'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Hidrant güncellendi',
				},
				CONTINUOUS_IRRIGATION: 'Kesintisiz Sulama',
				SETTINGS_SAVED: 'Hidrant ayarları kaydedildi',
				ENERGY_ANALYZER: 'Enerji Analizörü',
				MSGS : {
					SELECT_ONE_VALVE: 'En az bir çıkış seçilmelidir',
					VALVE_IDS_MUST_BE_UNIQUE: 'Vana nolar birbirinden farklı olmalıdır',
					FLUXMETER_IDS_MUST_BE_UNIQUE: 'Debimetre nolar birbirinden farklı olmalıdır',
					SELECT_IRRIGATION_REQUEST_TYPE: 'Her vana için sulama tipi seçilmelidir'
				},
				PRODUCT_ID: 'Donanım',
				FW_VERSION: 'Yazılım',
			},
			PRICETARIFFS: {
				PRICE_RATE: 'Fiyat',
				ENTER_PRICE_RATE: 'Lütfen fiyatı girin. Örnek fiyat formatı 20,25',
				START_DATE: 'Başlangıç Tarihi',
				END_DATE: 'Bitiş Tarihi',
				PRICETARIFF_LIST: 'Fiyat Tarifeleri',
				PRICETARIFFRATE_LIST: 'Tarife Fiyat Listesi',
				NEW_PRICE_TARIFF: 'Yeni Fiyat Tarifesi',
				EDIT_PRICE_TARIFF: 'Fiyat Tarifesi Güncelle',
				PRICE_RATE_TYPE: 'Fiyat Tipi',
				ENTER_PRICE_RATE_TYPE: 'Fiyat tipini seçiniz',
				ADD: 'Ekle',
				PRICETARIFF: 'Fiyat Tarifesi',
				END_DATE_MUST_BE_AFTER_START_DATE_OR_MUST_BE_NULL: 'Bitiş tarihi başlangıçtan sonra ya da boş olmalıdır',
				START_DATE_MUST_NOT_BE_NULL: 'Başlangıç tarihi boş olamaz',
				PRICE_RATE_MUST_NOT_BE_NULL: 'Fiyat boş olamaz',
				VALIDATION_PRICE_RATE: 'Geçerli bir fiyat giriniz.',
				VALIDATION_EXT_FIELD:'1 ile 10 arası bir değer giriniz.',
				VALIDATION_START_DATE:'Geçerli bir başlangıç tarihi giriniz.',
				EXTERNAL_FIELD_COEF:'Saha Dışı Fiyat Katsayısı',
				ENTER_EXTERNAL_FIELD_COEF:'Lütfen saha dışı fiyat katsayısını giriniz',
				EDIT_PRICE_RATE: 'Fiyat Güncelle',
				UPDATE_MESSAGE: 'Fiyat güncellendi',
				ADD_MESSAGE: 'Fiyat eklendi',
				HYDRANT_PRICE_TARIFF: 'Hidrant Fiyat Tarifesi',
				DELETE_PRICE_RATE: {
					DELETE: 'Sil',
					TITLE: 'Fiyat Sil',
					DESCRIPTION: 'Seçtiğiniz fiyat silinecektir. Devam etmek istiyor musunuz?',
					WAIT_DESCRIPTION: 'Fiyat siliniyor...',
					MESSAGE: 'Fiyat silindi',
					ERR_MESSAGE: 'Fiyat silinemedi'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Fiyat tarifesi güncellendi',
					ADD_MESSAGE: 'Fiyat tarifesi oluşturuldu'
				},
			},
			LINES: {
				CREATE_NEW_LINE: 'Yeni Hat',
				LINE_LIST: 'Hat Listesi',
				EDIT_LINE: 'Hattı Güncelle',
				OFFLINE: 'Devre Dışı',
				COOP_NAME: 'Kooperatif',
				NEW_LINE: 'Yeni Hat',
				LINE_NAME: 'Hat İsmi',
				ENTER_LINE_NAME: 'Lütfen hat ismini giriniz',
				IMEI: 'Imei',
				ENTER_IMEI: 'Lütfen imei bilgisini giriniz',
				IMEI_VALIDATION_ERR: 'Imei 15 haneli bir sayı olmalıdır',
				ELEVATION: 'Rakım',
				ENTER_ELEVATION: 'Lütfen rakım bilgisini giriniz',
				ELEVATION_VALIDATION_ERR: 'Geçerli bir rakım bilgisi girin',
				LATITUDE: 'Enlem',
				ENTER_LATITUDE: 'Lütfen enlem bilgisini giriniz',
				LATITUDE_VALIDATION_ERR: 'Geçerli bir enlem bilgisi girin',
				LONGTITUDE: 'Boylam',
				ENTER_LONGTITUDE: 'Lütfen boylam bilgisini giriniz',
				LONGTITUDE_VALIDATION_ERR: 'Geçerli bir boylam bilgisi girin',
				COOP: 'Bağlı Olduğu Kooperatif',
				COOP_MUST_BE_SELECTED_ERR: 'Bağlı olduğu kooperatifi seçmelisiniz',
				IMEINO_EXIST: 'imei numarası sistemde mevcuttur',
				SELECTED_NODES: 'Seçili Hat/Düğüm',
				AVAILABLE_NODES: 'Boştaki Hat/Düğüm',
				EDIT: {
					UPDATE_MESSAGE: 'Hat güncellendi',
					ADD_MESSAGE: 'Hat oluşturuldu'
				},
				TOGGLE_OFFLINE: 'Devre Dışı',
				TOGGLE_ONLINE: 'Aktif',
				TOGGLE_FIELD: 'Tarla',
				TOGGLE_WELL: 'Kuyu',
			}
		},
		PAGINATOR: {
			ITEMS_PER_PAGE: 'Sayfa başına öğe',
			NEXT_PAGE: 'Sonraki sayfa',
			PREVIOUS_PAGE: 'Sonraki sayfa',
			FIRST_PAGE: 'İlk sayfa',
			LAST_PAGE: 'Son sayfa',
		},
		FAQ: {
			TITLE: 'Sıkça Sorulan Sorular',
			NEW: 'Soru & Cevap Ekle',
			SEND: 'Soru Gönder',
			EDIT: 'Değiştir',
			DELETE: 'Sil',
		},
	}
};
