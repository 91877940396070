import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import { AuthenticationService} from './authentication.service';
import { Observable, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import { InterceptorSkipHeader } from './auth.constant';
@Injectable({
  providedIn: 'root'
})


export class TokenInterceptorService implements HttpInterceptor {
  
  constructor(public auth: AuthenticationService, private router: Router) { }
/*
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let accessToken;
    this.auth.getAccessToken().subscribe(result => accessToken = result);
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return next.handle(request);
  }

*/
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let accessToken;
    this.auth.getAccessToken().subscribe(result => accessToken = result);
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return next.handle(request).pipe(catchError((error, caught) => {
      this.handleAuthError(error);
      return of(error);
    }) as any);
}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      console.log('handled error ' + err.status);
      this.auth.logout();
      this.router.navigate([`/login`]);
      return of(err.message);
    }
    throw err;
  }
}
