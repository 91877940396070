import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpUtilsService } from '../../../core/services/http-utils.service';
import { AppSettings } from './../../../app.config';
import { map, mergeMap } from 'rxjs/operators';
import { QueryParamsModel } from '../../../core/models/query-models/query-params.model';
import { Observable, of } from 'rxjs';
import { QueryResultsModel } from '../../../core/models/query-models/query-results.model';
import { User } from '../../../core/models/user';
import { Person } from '../models/person.model';
import { ApiRequestParam } from '../models/request-entity/api-request-param';
import { UserType } from '../models/constants/user-type';
import { Roles } from '../models/constants/role';
const API_URL: string = AppSettings.API_ENDPOINT + 'user';


@Injectable({
    providedIn: 'root',
})
export class UserService {
    public urls: string;
    constructor(
        private http: HttpClient, private httpUtils: HttpUtilsService) {
    }

    public getById(id: number) {
        if (!id) {
            return;
        }
        const url = API_URL;
        let params = new HttpParams();
        params = params.append('id', id.toString());
        return this.http.get(url, { params }).pipe(
            map((result: any) => {
                return result;
            })
        );
    }

    public create(user: User, roleId: number, parentId: number) {
        const url = API_URL + '/createUserAccount?' + 'parentId=' + parentId + '&roleId=' + roleId;
        return this.http.post(url, user).pipe(
            map((result: any) => {
                return result;
            })
        );
    }

    public update(user: User) {
        const url = API_URL + '/updatePerson';
        return this.http.put(url, user).pipe(
            map((result: any) => {
                return result;
            })
        );
    }
    public updatePassword(user: User) {
        const url = API_URL + '/updatePassword';
        return this.http.put(url, user).pipe(
            map((result: any) => {
                return result;
            })
        );
    }

    public findUsers(queryParams: QueryParamsModel, role: string, type: string, directorateId?: number,
        coopId?: number): Observable<QueryResultsModel> {
        let params = new HttpParams();
        if (role === Roles.ADMIN.name) {
            if (type === UserType.DIRECTORATE.name) {
                this.urls = '/list/directorate';
                params = params.append('directorateId', directorateId.toString());
            }
            else if (type === UserType.COOP.name) {
                this.urls = '/list/coop';
                params = params.append('coopId', coopId.toString());
            }
            else if (type === UserType.MEMBER.name) {
                this.urls = '/list/member';
                params = params.append('coopId', coopId.toString());  // bunun memberId olması lazım
            }
        }
        else if (role === Roles.DIRECTORATE_ADMIN.name) {
            if (type === UserType.DIRECTORATE.name) {
                this.urls = '/list/directorate';
                params = params.append('directorateId', directorateId.toString());
            }
            else if (type === UserType.COOP.name) {
                this.urls = '/list/coop';
                params = params.append('coopId', coopId.toString());
            }
            else if (type === UserType.MEMBER.name) {
                this.urls = '/list/member';
                params = params.append('coopId', coopId.toString());  // bunun memberId olması lazım
            }
        }
        else if (role === Roles.COOP_ADMIN.name) {
            if (type === UserType.COOP.name) {
                this.urls = '/list/coop';
                params = params.append('coopId', coopId.toString());
            }
            else if (type === UserType.MEMBER.name) {
                this.urls = '/list/member';
                params = params.append('coopId', coopId.toString());  // bunun memberId olması lazım
            }
        }
        const url = API_URL + this.urls;
        return this.http.get<User[]>(url, { params }).pipe(
            mergeMap(res => {
                const personList: Person[] = [];
                res.forEach(element => {
                    personList.push(Person.fromJsonResponse(element));
                });

                const result = this.httpUtils.baseFilter(personList, queryParams, []);
                return of(result);
            })
        );
    }

    public getByIdentity(apiRequestParam: ApiRequestParam) {
        const url = API_URL + '/getByIdentity';
        return this.http.post(url, apiRequestParam).pipe(
            map((result: any) => {
                return result;
            })
        );
    }

    public fetchMe() {
        var url = API_URL + "/me";
       
        return new Promise((resolve, reject) => {
           this.http.get(url).subscribe(res => {
              resolve(res); 
            }, (err) => {
              reject(err);
            });
          });
      }
}
