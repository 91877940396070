import { ConfigModel } from '../interfaces/config';

export interface AclInterface {
	permissions: any;
	currentUserRoles: any;
}

export class AclModel implements AclInterface, ConfigModel {
	public config: any;

	public permissions: any = {
		ADMIN: 				['canDoAnything'],
		DIRECTORATE: 		['canDoAnything'],
		DIRECTORATE_ADMIN: 	['canDoAnything'],
		COOP: 				['canDoAnything'],
		COOP_ADMIN: 		['canDoAnything'],
		FARMER: 			['canDoAnything']
	};

	// store an object of current user roles
	public currentUserRoles: any = {};

	constructor() {}
}
