// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			aside: {
				self: {},
				items: [
					{
						title: 'Dashboard',
						desc: 'Dashboard of wells',
						root: true,
						icon: 'flaticon-line-graph',
						page: '/',
						translate: 'MENU.DASHBOARD'
					},
					/*
					{
						title: 'Map',
						desc: 'Map of fields and lines',
						root: true,
						icon: 'flaticon-line-graph',
						page: '/map',
						translate: 'MENU.MAP'
					},*/
					{
						title: 'ReservationIrrigation',
						desc: 'Page to create well reservation',
						root: true,
						icon: 'la la-tint',
						page: '/wellreservation',
						translate: 'MENU.RESERVATION',
						roles: ['ADMIN', 'COOP', 'COOP_ADMIN', 'FARMER'],
					},
					{
						title: 'Directorates',
						desc: 'Pages to manage directorates',
						root: true,
						icon: 'flaticon-suitcase',
						translate: 'MENU.DIRECTORATES.MAIN',
						roles: ['ADMIN', 'DIRECTORATE_ADMIN'],
						submenu: [
							{
								title: 'Manage Directorates',
								desc: 'Page to manage directorates',
								root: true,
								icon: 'flaticon-settings',
								page: '/directorates',
								translate: 'MENU.DIRECTORATES.MANAGE',
								roles: ['ADMIN'],
							},
							{
								title: 'Manage Directorate Users',
								desc: 'Page to manage directorate users',
								root: true,
								icon: 'flaticon-users',
								page: '/users/directorate',
								translate: 'MENU.DIRECTORATES.USERS',
							}
						]
					},
					{
						title: 'Coops',
						desc: 'Pages to manage coops',
						root: true,
						icon: 'flaticon-truck',
						translate: 'MENU.COOPS.MAIN',
						roles: ['ADMIN', 'DIRECTORATE', 'DIRECTORATE_ADMIN', 'COOP_ADMIN'],
						submenu: [
							{
								title: 'Coop Settings',
								desc: 'Page to manage coop settings',
								root: true,
								icon: 'flaticon-settings',
								page: '/coopsettings',
								translate: 'MENU.COOPS.SETTINGS',
								roles: ['COOP_ADMIN']
							},
							{
								title: 'Manage Coops',
								desc: 'Page to manage coops',
								root: true,
								icon: 'flaticon-settings',
								page: '/coops',
								translate: 'MENU.COOPS.MANAGE',
								roles: ['ADMIN', 'DIRECTORATE', 'DIRECTORATE_ADMIN']
							},
							{
								title: 'Manage Wells',
								desc: 'Page to manage wells',
								root: true,
								icon: 'flaticon-download',
								page: '/wells',
								translate: 'MENU.COOPS.WELLS',
							},
							{
								title: 'Manage Hydrants',
								desc: 'Page to manage hydrants',
								root: true,
								icon: 'flaticon-download',
								page: '/hydrants',
								translate: 'MENU.COOPS.HYDRANTS',
							},
							{
								title: 'Manage Irrigation Lines',
								desc: 'Page to manage irrigation lines',
								root: true,
								icon: 'flaticon-download',
								page: '/lines',
								translate: 'MENU.COOPS.LINES',
								roles: ['ADMIN']
							},
							/*
							{
								title: 'View Irrigation Mains',
								desc: 'Page to view irrigation mains',
								root: true,
								icon: 'flaticon-download',
								page: '/mains',
								translate: 'MENU.COOPS.MAINS',
								roles: ['ADMIN']
							},
							*/
							{
								title: 'Manage Price Tariffs',
								desc: 'Page to manage price tariffs',
								root: true,
								icon: 'flaticon-download',
								page: '/priceTariffs',
								translate: 'MENU.COOPS.PRICETARIFFS',
							},
							{
								title: 'Manage Fields',
								desc: 'Page to manage fields',
								root: true,
								icon: 'flaticon-layers',
								page: '/fields',
								translate: 'MENU.COOPS.FIELDS',
							},
							{
								title: 'Manage Coop Users',
								desc: 'Page to manage coop users',
								root: true,
								icon: 'flaticon-users',
								page: '/users/coop',
								translate: 'MENU.COOPS.USERS',
								roles: ['ADMIN', 'COOP', 'COOP_ADMIN']
							},
						]
					},
					{
						title: 'Members',
						desc: 'Pages to manage members',
						root: true,
						icon: 'flaticon-customer',
						translate: 'MENU.MEMBERS.MAIN',
						roles: ['ADMIN', 'DIRECTORATE', 'COOP', 'DIRECTORATE_ADMIN', 'COOP_ADMIN'],
						submenu: [
							{
								title: 'Manage Members',
								desc: 'Page to manage members',
								root: true,
								icon: 'flaticon-settings',
								page: '/members',
								translate: 'MENU.MEMBERS.MANAGE',
								roles: ['ADMIN', 'DIRECTORATE', 'COOP', 'DIRECTORATE_ADMIN', 'COOP_ADMIN']
							},
							{
								title: 'Manage Member Users',
								desc: 'Page to manage member users',
								root: true,
								icon: 'flaticon-users',
								page: '/users/member',
								translate: 'MENU.MEMBERS.USERS',
								roles: ['ADMIN', 'COOP_ADMIN']
							},
						]
					},
					{
						title: 'Manage Crops',
						desc: 'Page to manage crops',
						root: true,
						icon: 'la la-leaf',
						page: '/crops',
						translate: 'MENU.CROPS',
						roles: ['ADMIN', 'DIRECTORATE', 'COOP', 'DIRECTORATE_ADMIN',  'COOP_ADMIN']
					},
					{
						title: 'Frequently Asked Questions',
						desc: 'Page to display and manage frequently asked questions',
						root: true,
						icon: 'la la-question-circle',
						page: '/faq',
						translate: 'MENU.FAQ',
						roles: ['ADMIN', 'DIRECTORATE', 'DIRECTORATE_ADMIN', 'COOP', 'COOP_ADMIN', 'FARMER']
					},
					{
						title: 'Announcements',
						desc: 'Page to manage announcements',
						root: true,
						icon: 'la la-bullhorn',
						page: '/announcements',
						translate: 'MENU.ANNOUNCEMENTS',
						roles: ['ADMIN', 'DIRECTORATE', 'DIRECTORATE_ADMIN', 'COOP', 'COOP_ADMIN', 'FARMER']
					},
					{
						title: 'Forms',
						desc: 'Pages to view the forms and receipts',
						root: true,
						icon: 'flaticon-folder',
						translate: 'MENU.FORMS.MAIN',
						roles: ['ADMIN', 'COOP_ADMIN'],
						submenu: [
							{
								title: 'Receipt Book',
								desc: 'Receipt Book',
								root: true,
								icon: 'flaticon-folder',
								page: '/receiptBook',
								translate: 'MENU.FORMS.RECEIPT_BOOK',
								roles: ['ADMIN', 'COOP_ADMIN'],
							},
							{
								title: 'Irrigation Forms',
								desc: 'Irrigation Forms',
								root: true,
								icon: 'flaticon-folder',
								page: '/irrigationForm',
								translate: 'MENU.FORMS.IRRIGATION_FORM',
								roles: ['ADMIN', 'COOP_ADMIN'],
							},
							{
								title: 'Monthly Summary',
								desc: 'Monthly Summary',
								root: true,
								icon: 'flaticon-folder',
								page: '/monthlySummary',
								translate: 'MENU.FORMS.MONTHLY_SUMMARY',
								roles: ['ADMIN', 'COOP_ADMIN'],
							},
						]
					},
					{
						title: 'Reports',
						desc: 'Pages to generate and view reports',
						root: true,
						icon: 'flaticon-diagram',
						translate: 'MENU.REPORTS.MAIN',
						roles: ['ADMIN', 'DIRECTORATE_ADMIN', 'COOP_ADMIN', 'FARMER'],
						submenu: [
							{
								title: 'Well Consumption Report',
								desc: 'Report for well/hydrant production',
								root: true,
								icon: 'flaticon-diagram',
								page: '/wellConsumptionReport',
								translate: 'MENU.REPORTS.WELL_PRODUCTION',
								roles: ['ADMIN', 'DIRECTORATE_ADMIN', 'COOP_ADMIN'],
							},
							{
								title: 'Member Production Report',
								desc: 'Report for member based production',
								root: true,
								icon: 'flaticon-diagram',
								page: '/wellMemberConsumptionReport',
								translate: 'MENU.REPORTS.MEMBER_PRODUCTION',
								roles: ['ADMIN', 'DIRECTORATE_ADMIN', 'COOP_ADMIN'],
							},
							{
								title: 'Field Production Report',
								desc: 'Report for field based production',
								root: true,
								icon: 'flaticon-diagram',
								page: '/fieldConsumptionReport',
								translate: 'MENU.REPORTS.FIELD_PRODUCTION',
								roles: ['ADMIN', 'DIRECTORATE_ADMIN', 'COOP_ADMIN'],
							},
							{
								title: 'Field-Well Consumption Report',
								desc: 'Report for field-well consumption',
								root: true,
								icon: 'flaticon-diagram',
								page: '/fieldWellConsumptionReport',
								translate: 'MENU.REPORTS.FIELD_WELL_CONSUMPTION',
								roles: ['ADMIN', 'DIRECTORATE_ADMIN', 'COOP_ADMIN'],
							},
							{
								title: 'Member Consumption Report',
								desc: 'Report for member consumption',
								root: true,
								icon: 'flaticon-diagram',
								page: '/memberConsumptionReport',
								translate: 'MENU.REPORTS.MEMBER_CONSUMPTION',
								roles: ['ADMIN', 'DIRECTORATE_ADMIN', 'COOP_ADMIN', 'FARMER'],
							},
							{
								title: 'Member Cash Report',
								desc: 'Report for member cash',
								root: true,
								icon: 'flaticon-diagram',
								page: '/memberCashReport',
								translate: 'MENU.REPORTS.MEMBER_CASH',
								roles: ['ADMIN', 'COOP_ADMIN', 'FARMER'],
							},
							{
								title: 'Income Expense Report',
								desc: 'Report for income expense',
								root: true,
								icon: 'flaticon-diagram',
								page: '/incomeExpenseReport',
								translate: 'MENU.REPORTS.INCOME_EXPENSE',
								roles: ['ADMIN', 'COOP_ADMIN'],
							},
							{
								title: 'Irrigation History Report',
								desc: 'Report for irrigation history',
								root: true,
								icon: 'flaticon-diagram',
								page: '/irrigationHistoryReport',
								translate: 'MENU.REPORTS.IRRIGATION_HISTORY',
								roles: ['ADMIN', 'DIRECTORATE_ADMIN', 'COOP_ADMIN'],
							},
							/*
							{
								title: 'Crop Irrigation',
								desc: 'Report for water consumpton of crops',
								root: true,
								icon: 'flaticon-diagram',
								page: '/cropIrrigationReport',
								translate: 'MENU.REPORTS.CROP_IRRIGATION',
								roles: ['ADMIN', 'DIRECTORATE',  'COOP', 'DIRECTORATE_ADMIN', 'COOP_ADMIN']
							},
							*/
						]
					},
					{
						section: 'MENU.SETTINGS.MAIN',
						roles: ['ADMIN']
					},
					{
						title: 'SMS Settings',
						desc: 'SMS Settings',
						root: true,
						icon: 'flaticon-chat-2',
						page: '/smsSettings',
						translate: 'MENU.SETTINGS.SMS_SETTINGS',
						roles: ['ADMIN'],
					},
					{
						title: 'Email Settings',
						desc: 'Email Settings',
						root: true,
						icon: 'flaticon-email',
						page: '/emailSettings',
						translate: 'MENU.SETTINGS.EMAIL_SETTINGS',
						roles: ['ADMIN'],
					}
				]
			}
		};
	}
}
