import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { Subscription, timer } from 'rxjs';
import { UserService } from '../../../../pages/services/user.service';
import { Roles } from '../../../../pages/models/constants/role';
@Component({
	selector: 'm-user-profile',
	templateUrl: './user-profile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
	public statusTimer;
	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

	@Input() avatar: string = './assets/irrimet-web/media/img/misc/user.jpg';
	@Input() avatarBg: SafeStyle = '';

	@ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;
	public authenticatedUser: string;
	public balance = 'balance';
	public subscription: Subscription;
	constructor (
		private authService: AuthenticationService,
		private sanitizer: DomSanitizer,
		private userService: UserService,
		private cdr: ChangeDetectorRef
	) {

	}

	ngOnInit (): void {
		if (!this.avatarBg) {
			this.avatarBg = this.sanitizer.bypassSecurityTrustStyle('url(./assets/app/media/img/misc/user_profile_bg.jpg)');
		}
		if (this.authService.authenticatedUser !== null && this.authService.authenticatedUserRoles !== null &&
			this.authService.authenticatedUserRoles.includes(Roles.FARMER.name)) {
			this.initiateTimer();
		}
	}
	public logout () {
		this.authService.logout(true);
	}

	public initiateTimer() {
        this.statusTimer = timer(0, 30000);
        this.subscription = this.statusTimer.subscribe((t) => {

            this.fetchMe();
        });
    }
	private fetchMe() {
		this.userService.fetchMe().then(
        (data: any) => {
            if (data !== null) {
                this.authService.authenticatedUser.totalBalance = data.balance ? data.balance : 0;
                this.authService.authenticatedUser.availableBalance = data.availableBalance ? data.availableBalance : 0;
				this.balance = '' + this.authService.authenticatedUser.availableBalance;// + ' / ' + this.authService.authenticatedUser.totalBalance;
				this.cdr.detectChanges();
            }
        },
        (error) => {
        });
	}
}
