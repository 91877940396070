import { Coop } from "../../content/pages/models/coop.model";
import { Directorate } from "../../content/pages/models/directorate.model";

export class User {
    public id: number = 0;
    public username: string;
    public password: string;
    public personId: number;
    public name: string;
    public surname: string;
    public identityNumber: string;
    public phone: string;
    public email: string;
    public authorities: {};
    public roleId: number;
    public availableBalance: number;
    public totalBalance: number;
    public directorate: Directorate;
    public coop: Coop;
    public directorateName: string;
    public coopName: string;

    public clear() {
        this.id = 0;
        this.name = '';
        this.username = '';
        this.password = null;
        this.personId = 0;
        this.surname = '';
        this.identityNumber = '';
        this.phone = '';
        this.email = '';
        this.roleId = -1;
        this.availableBalance = 0;
        this.totalBalance = 0;
    }

    constructor() {
        if (this.directorate) {
            this.directorateName = this.directorate.name;
        }
        if (this.coop) {
            this.coopName = this.coop.name;
        }
    }

}
