export interface Role {
	id: number;
	name: string;
}
export const Roles = {
    'ADMIN':                { id: 0, name: 'ADMIN' },
    'DIRECTORATE':          { id: 1, name: 'DIRECTORATE' },
    'COOPUNION':            { id: 2, name: 'COOPUNION' },
    'COOP':                 { id: 3, name: 'COOP' },
    'FARMER':               { id: 4, name: 'FARMER' },
    'DIRECTORATE_ADMIN':    { id: 101, name: 'DIRECTORATE_ADMIN' },
    'COOPUNION_ADMIN':      { id: 102, name: 'COOPUNION_ADMIN' },
    'COOP_ADMIN':           { id: 103, name: 'COOP_ADMIN' },

 };
