import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { QueryParamsModel } from '../../core/models/query-models/query-params.model';
import { QueryResultsModel } from '../../core/models/query-models/query-results.model';
import * as _ from 'lodash';
@Injectable()
export class HttpUtilsService {

	getFindHTTPParams(queryParams): HttpParams {
		const params = new HttpParams()
			.set('lastNamefilter', queryParams.filter)
			.set('sortOrder', queryParams.sortOrder)
			.set('sortField', queryParams.sortField)
			.set('pageNumber', queryParams.pageNumber.toString())
			.set('pageSize', queryParams.pageSize.toString());

		return params;
	}

	getHTTPHeader() {
		return {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};
	}

	baseFilter(_entities: any[], _queryParams: QueryParamsModel, _filtrationFields: string[] = []): QueryResultsModel {
		if (_entities === null || _entities === undefined) {
			const emptyQueryResults = new QueryResultsModel();
			emptyQueryResults.items = _entities;
			emptyQueryResults.totalCount = 0;
			return emptyQueryResults;
		}
		if (_queryParams === null || _queryParams === undefined) {
			let nonFilteredTotalCount = 0;
			const nonFilteredResults = new QueryResultsModel();
			nonFilteredResults.items = _entities;
			if (_entities !== null && _entities !== undefined) {
				nonFilteredTotalCount = _entities.length;
			}
			nonFilteredResults.totalCount = nonFilteredTotalCount;
			return nonFilteredResults;
		}
		// Filtration
		let entitiesResult = this.searchInArray(_entities, _queryParams.filter, _filtrationFields);

		// Sorting
		// start
		if (_queryParams.sortField) {
			entitiesResult = this.sortArray(entitiesResult, _queryParams.sortField, _queryParams.sortOrder);
		}
		// end

		// Paginator
		// start
		const totalCount = entitiesResult.length;
		const initialPos = _queryParams.pageNumber * _queryParams.pageSize;
		entitiesResult = entitiesResult.slice(initialPos, initialPos + _queryParams.pageSize);
		// end

		const queryResults = new QueryResultsModel();
		queryResults.items = entitiesResult;
		queryResults.totalCount = totalCount;
		return queryResults;
	}

	sortArray(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {
		if (!_sortField) {
			return _incomingArray;
		}

		let result: any[] = [];
		result = _incomingArray.sort((a, b) => {
			let compareResult = 0;
			if (a[_sortField] === undefined || a[_sortField] === null) {
				compareResult = -1;
			} else if (b[_sortField] === undefined || b[_sortField] === null) {
				compareResult = 1;
			} else {
				const varType = (a[_sortField]).constructor.name;
				if (varType === 'Number') {
					if (a[_sortField] < b[_sortField]) {
						compareResult = -1 ;
					} else if (a[_sortField] > b[_sortField]) {
						compareResult = 1;
					} else {
						compareResult = 0;
					}
				} else {
					compareResult = a[_sortField].localeCompare(b[_sortField]);
				}
			}
			if (_sortOrder === 'desc') {
				compareResult = compareResult * -1;
			}
			return compareResult;
			/*
			if (a[_sortField] < b[_sortField]) {
				return _sortOrder === 'asc' ? -1 : 1;
			}

			if (a[_sortField] > b[_sortField]) {
				return _sortOrder === 'asc' ? 1 : -1;
			}

			return 0;
			*/
		});
		return result;
	}

	searchInArray(_incomingArray: any[], _queryObj: any, _filtrationFields: string[] = []): any[] {
		const result: any[] = [];
		let resultBuffer: any[] = [];
		const indexes: number[] = [];
		let firstIndexes: number[] = [];
		let doSearch: boolean = false;
		_filtrationFields.forEach(item => {
			if (item in _queryObj) {
				_incomingArray.forEach((element, index) => {
					if (element[item] === _queryObj[item]) {
						firstIndexes.push(index);
					}
				});
				firstIndexes.forEach(element => {
					resultBuffer.push(_incomingArray[element]);
				});
				_incomingArray = resultBuffer.slice(0);
				resultBuffer = [].slice(0);
				firstIndexes = [].slice(0);
			}
		});

		Object.keys(_queryObj).forEach(key => {
			const searchText = _queryObj[key].toString().trim().toLocaleLowerCase('tr');
			if (key && !_.includes(_filtrationFields, key) && searchText) {
				doSearch = true;
				try {
					_incomingArray.forEach((element, index) => {
						if (element[key] !== null) {
							const _val = element[key].toString().trim().toLocaleLowerCase('tr');
							if (_val.indexOf(searchText) > -1 && indexes.indexOf(index) === -1) {
								indexes.push(index);
							}
						}
					});
				} catch (ex) {
					console.log(ex, key, searchText);
				}
			}
		});

		if (!doSearch) {
			return _incomingArray;
		}

		indexes.forEach(re => {
			result.push(_incomingArray[re]);
		});

		return result;
	}

}
