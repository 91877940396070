import { ConfigModel } from '../core/interfaces/config';

export class PagesConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			'/': {
				page: {
					title: 'MENU.DASHBOARD',
					translate: 'MENU.DASHBOARD',
					desc: 'Dashboard of wells',
					subheader: false
				}
			},
			map: {
				page: {
					title: 'MENU.MAP',
					translate: 'MENU.MAP',
					desc: 'Page to view map of fields and lines',
					subheader: false
				}
			},
			wellreservation: {
				page: {
					title: 'MENU.RESERVATION',
					translate: 'MENU.RESERVATION',
					desc: 'Page to create well reservation',
					subheader: false
				}
			},
			directorates: {
				page: {
					title: 'MENU.DIRECTORATES.MANAGE',
					translate: 'MENU.DIRECTORATES.MANAGE',
					desc: 'Page to manage directorates',
					subheader: false
				}
			},
			directorateusers: {
				page: {
					title: 'MENU.DIRECTORATES.USERS',
					translate: 'MENU.DIRECTORATES.USERS',
					desc: 'Page to manage directorate users',
					subheader: false
				}
			},
			coops: {
				page: {
					title: 'MENU.COOPS.MANAGE',
					translate: 'MENU.COOPS.MANAGE',
					desc: 'Page to manage coops',
					subheader: false
				}
			},
			coopsettings: {
				page: {
					title: 'MENU.COOPS.SETTINGS',
					translate: 'MENU.COOPS.SETTINGS',
					desc: 'Page to manage coop settings',
					subheader: false
				}
			},
			coopusers: {
				page: {
					title: 'MENU.COOPS.USERS',
					translate: 'MENU.COOPS.USERS',
					desc: 'Page to manage coop users',
					subheader: false
				}
			},
			members: {
				page: {
					title: 'MENU.MEMBERS.MANAGE',
					translate: 'MENU.MEMBERS.MANAGE',
					desc: 'Page to manage members',
					subheader: false
				}
			},
			memberusers: {
				page: {
					title: 'MENU.MEMBERS.USERS',
					translate: 'MENU.MEMBERS.USERS',
					desc: 'Page to manage member users',
					subheader: false
				}
			},
			wells: {
				page: {
					title: 'MENU.COOPS.WELLS',
					translate: 'MENU.COOPS.WELLS',
					desc: 'Page to manage coop wells',
					subheader: false
				}
			},
			hydrants: {
				page: {
					title: 'MENU.COOPS.HYDRANTS',
					translate: 'MENU.COOPS.HYDRANTS',
					desc: 'Page to manage coop hydrants',
					subheader: false
				}
			},
			lines: {
				page: {
					title: 'MENU.COOPS.LINES',
					translate: 'MENU.COOPS.LINES',
					desc: 'Page to manage coop irrigation lines',
					subheader: false
				}
			},
			mains: {
				page: {
					title: 'MENU.COOPS.MAINS',
					translate: 'MENU.COOPS.MAINS',
					desc: 'Page to view coop irrigation mains',
					subheader: false
				}
			},
			priceTariffs: {
				page: {
					title: 'MENU.COOPS.PRICETARIFFS',
					translate: 'MENU.COOPS.PRICETARIFFS',
					desc: 'Page to manage coop price tariffs',
					subheader: false
				}
			},
			fields: {
				page: {
					title: 'MENU.COOPS.FIELDS',
					translate: 'MENU.COOPS.FIELDS',
					desc: 'Page to manage coop fields',
					subheader: false
				}
			},
			wellConsumptionReport: {
				page: {
					title: 'MENU.REPORTS.WELL_CONSUMPTION',
					translate: 'MENU.REPORTS.WELL_CONSUMPTION',
					desc: 'Page to report well consumption',
					subheader: false
				}
			},
			wellMemberConsumptionReport: {
				page: {
					title: 'MENU.REPORTS.MEMBER_PRODUCTION',
					translate: 'MENU.REPORTS.MEMBER_PRODUCTION',
					desc: 'Page to report member based production',
					subheader: false
				}
			},
			fieldConsumptionReport: {
				page: {
					title: 'MENU.REPORTS.FIELD_PRODUCTION',
					translate: 'MENU.REPORTS.FIELD_PRODUCTION',
					desc: 'Page to report field based production',
					subheader: false
				}
			},
			fieldWellConsumptionReport: {
				page: {
					title: 'MENU.REPORTS.FIELD_WELL_CONSUMPTION',
					translate: 'MENU.REPORTS.FIELD_WELL_CONSUMPTION',
					desc: 'Page to report field-well consumption',
					subheader: false
				}
			},
			memberConsumptionReport: {
				page: {
					title: 'MENU.REPORTS.MEMBER_CONSUMPTION',
					translate: 'MENU.REPORTS.MEMBER_CONSUMPTION',
					desc: 'Page to report member consumption',
					subheader: false
				}
			},
			memberCashReport: {
				page: {
					title: 'MENU.REPORTS.MEMBER_CASH',
					translate: 'MENU.REPORTS.MEMBER_CASH',
					desc: 'Page to report member cash',
					subheader: false
				}
			},
			receiptBook: {
				page: {
					title: 'MENU.FORMS.RECEIPT_BOOK',
					translate: 'MENU.FORMS.RECEIPT_BOOK',
					desc: 'Receipt book',
					subheader: false
				}
			},
			irrigationForm: {
				page: {
					title: 'MENU.FORMS.IRRIGATION_FORM',
					translate: 'MENU.FORMS.IRRIGATION_FORM',
					desc: 'Irrigation forms',
					subheader: false
				}
			},
			monthlySummary: {
				page: {
					title: 'MENU.FORMS.MONTHLY_SUMMARY',
					translate: 'MENU.FORMS.MONTHLY_SUMMARY',
					desc: 'Monthly summary',
					subheader: false
				}
			},
			cropIrrigationReport: {
				page: {
					title: 'MENU.REPORTS.CROP_IRRIGATION',
					translate: 'MENU.REPORTS.CROP_IRRIGATION',
					desc: 'Report for water consumpton of crops',
					subheader: false
				}
			},
			incomeExpenseReport: {
				page: {
					title: 'MENU.REPORTS.INCOME_EXPENSE',
					translate: 'MENU.REPORTS.INCOME_EXPENSE',
					desc: 'Page to report income expense',
					subheader: false
				}
			},
			irrigationHistoryReport: {
				page: {
					title: 'MENU.REPORTS.IRRIGATION_HISTORY',
					translate: 'MENU.REPORTS.IRRIGATION_HISTORY',
					desc: 'Page to report irrigation history',
					subheader: false
				}
			},
			builder: {
				page: { title: 'Layout Builder', desc: 'Layout builder' }
			},
			header: {
				actions: {
					page: { title: 'Actions', desc: 'actions example page' }
				}
			},
			profile: {
				page: { title: 'User Profile', desc: '' }
			},
			404: {
				page: { title: '404 Not Found', desc: '', subheader: false }
			}
		};
	}
}
