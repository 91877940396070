import { Injectable } from '@angular/core';
import {MatPaginatorIntl} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class MatPaginatorIntlTr extends MatPaginatorIntl {
  public translate: TranslateService;
  public itemsPerPageLabel: string; // = 'Sayfa başına öğe';
  public nextPageLabel: string; //     = 'Sonraki sayfa';
  public previousPageLabel: string; // = 'Önceki sayfa';
  public firstPageLabel: string; // = 'İlk sayfa';
  public lastPageLabel: string; // = 'Son sayfa';

  public getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 / ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' / ' + length;
  };

  public injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  public translateLabels() {
    this.itemsPerPageLabel =  this.translate.instant('PAGINATOR.ITEMS_PER_PAGE');
    this.nextPageLabel     = this.translate.instant('PAGINATOR.NEXT_PAGE');
    this.previousPageLabel = this.translate.instant('PAGINATOR.PREVIOUS_PAGE');
    this.firstPageLabel = this.translate.instant('PAGINATOR.FIRST_PAGE');
    this.lastPageLabel = this.translate.instant('PAGINATOR.LAST_PAGE');
    this.changes.next();
  }
}
