import { Observable, Subject, from } from 'rxjs';
import { map, catchError, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { AccessData } from './access-data';
import { Credential } from './credential';
import { TOKEN_AUTH_PASSWORD, TOKEN_AUTH_USERNAME, InterceptorSkipHeader } from './auth.constant';
import { AppSettings } from './../../app.config';
import { User } from './../models/user';
import { JwtHelperService} from '@auth0/angular-jwt';
import { Permission } from './permission';
import { Roles } from '../../content/pages/models/constants/role';
import { MemberInfo } from './member-info';

@Injectable()
export class AuthenticationService implements AuthService {
	public static AUTH_TOKEN = AppSettings.BASE_URL + '/oauth/token';
	private jwtHelper: JwtHelperService;
	public accessToken: string;
    public authenticatedUser: User;
	public authenticatedUserRoles: string[];
	public authenticatedUserRoleDetails: Permission[];
	public authenticatedUserPermissions: string[];
    public authenticatedUserParentId: number;
	public organizationAdmin: boolean;
	public accessData: AccessData;
	public authenticatedUserMemberInfo: MemberInfo[];
	API_URL = 'api';
	API_ENDPOINT_LOGIN = '/login';
	API_ENDPOINT_REFRESH = '/refresh';
	API_ENDPOINT_REGISTER = '/register';
	public onCredentialUpdated$: Subject<AccessData>;

	constructor(
		private http: HttpClient,
		private tokenStorage: TokenStorage,
		private util: UtilsService
	) {
		this.onCredentialUpdated$ = new Subject();
		this.jwtHelper = new JwtHelperService();
		let storedAccessToken;
		this.getAccessToken().subscribe(result => storedAccessToken = result);
		if (storedAccessToken != null ) {
			if (this.jwtHelper.isTokenExpired(storedAccessToken)) {
				this.logout(true);
				return;
			} else {
				this.decodeToken(storedAccessToken);
			}
		}
	}

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
		let storedAccessToken;
		this.getAccessToken().subscribe(result => storedAccessToken = result);
		if (storedAccessToken === null ) {
			return null;
		}
		return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
	}

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}

	/**
	 * Get user roles
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		return this.tokenStorage.getUserRoles();
	}

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
	public refreshToken(): Observable<AccessData> {
		/*
		return this.tokenStorage.getRefreshToken().pipe(
			switchMap((refreshToken: string) => {
				return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_REFRESH + '?' + this.util.urlParam(refreshToken));
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(err => {
				this.logout();
				return throwError(err);
			})
		);
		*/
		return null;
	}

	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
	public refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	/**
	 * Verify that outgoing request is refresh-token,
	 * so interceptor won't intercept this request
	 * @param {string} url
	 * @returns {boolean}
	 */
	public verifyTokenRequest(url: string): boolean {
		return url.endsWith(this.API_ENDPOINT_REFRESH);
	}

	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public login(credential: Credential): Observable<any> {
		let httpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		httpHeaders = httpHeaders.set(InterceptorSkipHeader, '');
		httpHeaders = httpHeaders.append('Authorization', 'Basic ' + btoa(TOKEN_AUTH_USERNAME + ':' + TOKEN_AUTH_PASSWORD));
		let body = `username=${encodeURIComponent(credential.username)}`;
			body += `&password=${encodeURIComponent(credential.password)}`;
			body += `&grant_type=password`;

			return this.http.post<AccessData>(AuthenticationService.AUTH_TOKEN, body, { headers: httpHeaders}).pipe(
				map((result: any) => {
					if (result instanceof Array) {
						return result.pop();
					}
					return result;
				}),
				tap(this.saveAccessData.bind(this)),
				catchError(this.handleError('login', [undefined]))
			);
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			return from(result);
		};
	}

	/**
	 * Logout
	 */
	public logout(refresh?: boolean): void {
		this.tokenStorage.clear();
		if (refresh) {
			location.reload();
		}
	}

	/**
	 * Save access data in the storage
	 * @private
	 * @param {AccessData} data
	 */
	private saveAccessData(accessData: AccessData) {
		if (typeof accessData !== 'undefined') {
			this.decodeToken(accessData.access_token);
			this.tokenStorage
				.setAccessToken(accessData.access_token)
			// .setRefreshToken(accessData.refreshToken)
 				.setUserRoles(accessData.userDetails.authorities);
				this.onCredentialUpdated$.next(accessData);
		}
	}

	public containsRoles (roles) {
		const userRoles = this.authenticatedUserRoles;
		return roles.some(function (v) {
			return userRoles.indexOf(v) >= 0;
		});
	}

	public containsPermissions (permissions) {
		const userPermissions = this.authenticatedUserPermissions;
		if (userPermissions === undefined) {
			return true;
		}
		return permissions.some(function (v) {
			return userPermissions.indexOf(v) >= 0;
		});
	}
	private decodeToken(token: string) {
        this.accessToken = token;
        const decodedToken = this.jwtHelper.decodeToken(token);
        this.authenticatedUser = new User();
        this.authenticatedUser.username = decodedToken.user_name;
        this.authenticatedUser.id = decodedToken.userDetails.id;
        this.authenticatedUser.personId = decodedToken.userDetails.personId;
        this.authenticatedUser.name = decodedToken.userDetails.name;
        this.authenticatedUser.surname = decodedToken.userDetails.surname;
        this.authenticatedUser.identityNumber = decodedToken.userDetails.identityNumber;
        this.authenticatedUser.email = decodedToken.userDetails.email;
		this.authenticatedUser.phone = decodedToken.userDetails.phone;
		this.authenticatedUserRoles = decodedToken.userDetails.authorities;
		this.authenticatedUserRoleDetails = decodedToken.userDetails.roleDetails;
		this.authenticatedUserPermissions = decodedToken.userDetails.permissions;
		this.authenticatedUserParentId = decodedToken.userDetails.parentId;
		this.authenticatedUserMemberInfo = decodedToken.userDetails.memberInfo;
  		this.organizationAdmin = decodedToken.userDetails.admin;
   	}

	/**
	 * Submit registration request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public register(credential: Credential): Observable<any> {
		// dummy token creation
		credential = Object.assign({}, credential, {
			accessToken: 'access-token-' + Math.random(),
			refreshToken: 'access-token-' + Math.random(),
			roles: ['USER'],
		});
		return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential)
			.pipe(catchError(this.handleError('register', []))
		);
	}

	/**
	 * Submit forgot password request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public requestPassword(credential: Credential): Observable<any> {
		return this.http.get(this.API_URL + this.API_ENDPOINT_LOGIN + '?' + this.util.urlParam(credential))
			.pipe(catchError(this.handleError('forgot-password', []))
		);
	}

	public hasDirectorateRole(): boolean {
		if (this.authenticatedUserRoles === null || this.authenticatedUserRoles === undefined) {
			return false;
		}
		if (this.authenticatedUserRoles.includes(Roles.DIRECTORATE.name) || this.authenticatedUserRoles.includes(Roles.DIRECTORATE_ADMIN.name)) {
			return true;
		} else {
			return false;
		}
	}

	public hasDirectorateAdminRole(): boolean {
		if (this.authenticatedUserRoles === null || this.authenticatedUserRoles === undefined) {
			return false;
		}
		if (this.authenticatedUserRoles.includes(Roles.DIRECTORATE_ADMIN.name)) {
			return true;
		} else {
			return false;
		}
	}

	public hasCoopRole(): boolean {
		if (this.authenticatedUserRoles === null || this.authenticatedUserRoles === undefined) {
			return false;
		}
		if (this.authenticatedUserRoles.includes(Roles.COOP.name) || this.authenticatedUserRoles.includes(Roles.COOP_ADMIN.name)) {
			return true;
		} else {
			return false;
		}
	}

	public hasCoopAdminRole(): boolean {
		if (this.authenticatedUserRoles === null || this.authenticatedUserRoles === undefined) {
			return false;
		}
		if (this.authenticatedUserRoles.includes(Roles.COOP_ADMIN.name)) {
			return true;
		} else {
			return false;
		}
	}

	public hasFarmerRole(): boolean {
		if (this.authenticatedUserRoles === null || this.authenticatedUserRoles === undefined) {
			return false;
		}
		if (this.authenticatedUserRoles.includes(Roles.FARMER.name)) {
			return true;
		} else {
			return false;
		}
	}
	public hasOnlyFarmerRole(): boolean {
		if (this.authenticatedUserRoles === null || this.authenticatedUserRoles === undefined) {
			return false;
		}
		if (this.authenticatedUserRoles.length === 1 && this.authenticatedUserRoles.includes(Roles.FARMER.name)) {
			return true;
		} else {
			return false;
		}
	}
	public hasAdminRole(): boolean {
		if (this.authenticatedUserRoles === null || this.authenticatedUserRoles === undefined) {
			return false;
		}
		if (this.authenticatedUserRoles.includes(Roles.ADMIN.name)) {
			return true;
		} else {
			return false;
		}
	}

	public getHighestRole(): string {
		if (this.authenticatedUserRoles === null || this.authenticatedUserRoles === undefined) {
			return 'GUEST';
		}
		if (this.authenticatedUserRoles.includes(Roles.ADMIN.name)) {
			return Roles.ADMIN.name;
		}
		if (this.authenticatedUserRoles.includes(Roles.DIRECTORATE_ADMIN.name)) {
			return Roles.DIRECTORATE_ADMIN.name;
		}
		if (this.authenticatedUserRoles.includes(Roles.DIRECTORATE.name)) {
			return Roles.DIRECTORATE.name;
		}
		if (this.authenticatedUserRoles.includes(Roles.COOP_ADMIN.name)) {
			return Roles.COOP_ADMIN.name;
		}
		if (this.authenticatedUserRoles.includes(Roles.COOP.name)) {
			return Roles.COOP.name;
		}
		if (this.authenticatedUserRoles.includes(Roles.FARMER.name)) {
			return Roles.FARMER.name;
		}
	}

	public isFarmerWithMultipleMemberships() {
		if (this.hasFarmerRole() && this.authenticatedUserMemberInfo  && this.authenticatedUserMemberInfo.length > 1) {
			return true;
		} else {
			return false;
		}
	}

	public deleteAccount(credential: Credential): Observable<any> {
		let httpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		httpHeaders = httpHeaders.set(InterceptorSkipHeader, '');
		httpHeaders = httpHeaders.append('Authorization', 'Basic ' + btoa(TOKEN_AUTH_USERNAME + ':' + TOKEN_AUTH_PASSWORD));
		let body = `username=${encodeURIComponent(credential.username)}`;
			body += `&password=${encodeURIComponent(credential.password)}`;
			body += `&grant_type=password`;

			return this.http.post<AccessData>(AuthenticationService.AUTH_TOKEN, body, { headers: httpHeaders}).pipe(
				map((result: any) => {
					if (result instanceof Array) {
						return result.pop();
					}
					return result;
				}),
				tap(this.saveAccessData.bind(this)),
				catchError(this.handleError('login', [undefined]))
			);
	}

}
